// NavBar.js
import React, { useState, useEffect } from 'react';
import { 
  AppBar, 
  Toolbar, 
  Typography, 
  Button, 
  IconButton, 
  Drawer, 
  List, 
  ListItem, 
  ListItemText, 
  Box, 
  Container,
  useScrollTrigger,
  Slide,
  useMediaQuery
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import { Link as RouterLink, useNavigate, useLocation } from 'react-router-dom';
import LogoIcon from '@mui/icons-material/Code'; // Placeholder for your logo

// HideOnScroll component for navbar behavior
function HideOnScroll(props) {
  const { children } = props;
  const trigger = useScrollTrigger();

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  );
}

const NavBar = () => {
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [navbarTransparent, setNavbarTransparent] = useState(true);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();
  const location = useLocation();

  // Navigation links
  const navLinks = [
    { name: 'Home', path: '/' },
    { name: 'Solutions', path: '/features' },
    { name: 'Consulting', path: '/consulting' },
    { name: 'Process', path: '/#process' },
    { name: 'FAQ', path: '/#faq' },
    { name: 'Contact', path: '/#contact' }
  ];

  // Handle scroll to update navbar transparency
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      if (scrollPosition > 50) {
        setNavbarTransparent(false);
      } else {
        setNavbarTransparent(true);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  // Helper function to handle smooth scrolling to sections
  const scrollToSection = (sectionId) => {
    setDrawerOpen(false);
    
    // Find the section in the current page
    const section = document.getElementById(sectionId);
    
    if (section) {
      // If we're on the page with the section, scroll to it
      section.scrollIntoView({ behavior: 'smooth', block: 'start' });
    } else {
      // Navigate to the page that has the section
      navigate('/', { replace: false });
      
      // After navigation, try to scroll to the section
      setTimeout(() => {
        const newSection = document.getElementById(sectionId);
        if (newSection) {
          newSection.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }, 500);
    }
  };

  // Handle navigation based on path
  const handleNavigation = (path) => {
    if (path.includes('#')) {
      const [pagePath, sectionId] = path.split('#');
      
      if (location.pathname === pagePath || (pagePath === '/' && location.pathname === '/')) {
        // If already on the correct page, just scroll
        scrollToSection(sectionId);
      } else {
        // Navigate first, then scroll after page loads
        navigate(pagePath);
        setTimeout(() => scrollToSection(sectionId), 500);
      }
    } else {
      // Regular page navigation
      navigate(path);
      setDrawerOpen(false);
      
      // Scroll to top when navigating to a new page
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  // Function to check if a link is active
  const isLinkActive = (link) => {
    if (link.path.includes('#')) {
      // For hash links, check if the base path matches
      const [basePath] = link.path.split('#');
      return location.pathname === basePath || 
             (basePath === '/' && location.pathname === '/');
    }
    
    // For regular links, direct comparison
    return location.pathname === link.path;
  };

  // Render mobile drawer list items
  const renderMobileNavLinks = () => {
    return navLinks.map((link) => {
      const active = isLinkActive(link);
      
      return (
        <ListItem 
          button
          key={link.name}
          onClick={() => handleNavigation(link.path)}
          sx={{
            borderRadius: 2,
            mb: 1,
            transition: 'all 0.2s ease',
            bgcolor: active ? 'rgba(142, 45, 226, 0.15)' : 'transparent',
            '&:hover': {
              bgcolor: 'rgba(142, 45, 226, 0.1)',
            },
            position: 'relative',
            '&::before': active ? {
              content: '""',
              position: 'absolute',
              left: 0,
              top: '50%',
              transform: 'translateY(-50%)',
              width: 4,
              height: '50%',
              bgcolor: 'primary.main',
              borderRadius: '0 4px 4px 0',
            } : {}
          }}
        >
          <ListItemText 
            primary={link.name} 
            primaryTypographyProps={{
              fontWeight: active ? 700 : 400,
              fontSize: '1.1rem',
            }}
          />
        </ListItem>
      );
    });
  };

  return (
    <>
      <HideOnScroll>
        <AppBar 
          position="fixed" 
          elevation={navbarTransparent ? 0 : 4}
          sx={{
            backgroundColor: navbarTransparent 
              ? 'rgba(15, 32, 39, 0.7)' 
              : 'rgba(15, 32, 39, 0.95)',
            backdropFilter: 'blur(8px)',
            transition: 'all 0.3s ease',
          }}
        >
          <Container maxWidth="xl">
            <Toolbar disableGutters sx={{ justifyContent: 'space-between' }}>
              {/* Logo and Brand */}
              <Box 
                component={RouterLink} 
                to="/"
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  textDecoration: 'none',
                  color: 'white',
                }}
              >
                <LogoIcon 
                  sx={{ 
                    display: { xs: 'none', md: 'flex' }, 
                    mr: 1,
                    color: theme.palette.primary.main,
                    fontSize: '2rem'
                  }} 
                />
                <Typography
                  variant="h6"
                  noWrap
                  sx={{
                    mr: 2,
                    display: 'flex',
                    fontFamily: 'monospace',
                    fontWeight: 700,
                    letterSpacing: '.2rem',
                    color: 'white',
                  }}
                >
                  REIGNOVATION
                </Typography>
              </Box>

              {/* Desktop Navigation Links */}
              {!isMobile && (
                <Box sx={{ display: 'flex', gap: 1 }}>
                  {navLinks.map((link) => (
                    <Button
                      key={link.name}
                      color="inherit"
                      onClick={() => handleNavigation(link.path)}
                      sx={{
                        mx: 0.5,
                        position: 'relative',
                        '&::after': {
                          content: '""',
                          position: 'absolute',
                          bottom: 0,
                          left: 0,
                          width: isLinkActive(link) ? '100%' : '0',
                          height: '2px',
                          bgcolor: 'primary.main',
                          transition: 'width 0.3s ease',
                        },
                        '&:hover::after': {
                          width: '100%',
                        },
                      }}
                    >
                      {link.name}
                    </Button>
                  ))}
                </Box>
              )}

              {/* Contact Button */}
              {!isMobile && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleNavigation('/#contact')}
                  sx={{
                    px: 3,
                    py: 1,
                    borderRadius: '50px',
                    boxShadow: '0 4px 14px 0 rgba(142, 45, 226, 0.4)',
                  }}
                >
                  Get Started
                </Button>
              )}

              {/* Mobile Menu Button */}
              {isMobile && (
                <IconButton
                  edge="end"
                  color="inherit"
                  aria-label="menu"
                  onClick={() => setDrawerOpen(true)}
                >
                  <MenuIcon />
                </IconButton>
              )}
            </Toolbar>
          </Container>
        </AppBar>
      </HideOnScroll>

      {/* Mobile Drawer Navigation */}
      <Drawer
        anchor="right"
        open={drawerOpen}
        onClose={() => setDrawerOpen(false)}
        PaperProps={{
          sx: {
            width: '100%',
            maxWidth: 350,
            bgcolor: 'background.paper',
            backgroundImage: 'none',
            boxShadow: '0px 0px 20px rgba(0, 0, 0, 0.5)',
          }
        }}
      >
        <Box sx={{ 
          p: 2, 
          display: 'flex', 
          justifyContent: 'flex-end' 
        }}>
          <IconButton 
            onClick={() => setDrawerOpen(false)}
            aria-label="close drawer"
          >
            <CloseIcon />
          </IconButton>
        </Box>
        
        <Box sx={{ 
          display: 'flex', 
          flexDirection: 'column', 
          alignItems: 'center',
          p: 2,
          mb: 4 
        }}>
          <LogoIcon 
            sx={{ 
              fontSize: 60, 
              color: 'primary.main',
              mb: 2 
            }} 
          />
          <Typography 
            variant="h5" 
            component="div"
            sx={{ 
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.2rem',
              color: 'text.primary',
              mb: 4
            }}
          >
            REIGNOVATION
          </Typography>
        </Box>

        <List sx={{ px: 2 }}>
          {renderMobileNavLinks()}
        </List>

        <Box sx={{ 
          p: 3, 
          mt: 'auto', 
          display: 'flex', 
          justifyContent: 'center' 
        }}>
          <Button
            variant="contained"
            fullWidth
            color="primary"
            onClick={() => handleNavigation('/#contact')}
            sx={{
              py: 1.5,
              borderRadius: '50px',
              boxShadow: '0 4px 14px 0 rgba(142, 45, 226, 0.4)',
            }}
          >
            Get Started
          </Button>
        </Box>
      </Drawer>

      {/* Toolbar spacer */}
      <Toolbar />
    </>
  );
};

export default NavBar;
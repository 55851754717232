// Features.js
import React, { useEffect, useRef } from 'react';
import { 
  Grid, 
  Card, 
  CardContent, 
  Typography, 
  Box, 
  Container,
  useMediaQuery,
  useTheme,
  IconButton,
  Button
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CodeIcon from '@mui/icons-material/Code';
import WebIcon from '@mui/icons-material/Web';
import BuildIcon from '@mui/icons-material/Build';
import SupportIcon from '@mui/icons-material/Support';
import InsightsIcon from '@mui/icons-material/Insights';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';

// Intersection Observer hook for animations
const useIntersectionObserver = (options) => {
  const ref = useRef(null);
  const [isVisible, setIsVisible] = React.useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setIsVisible(entry.isIntersecting);
    }, options);

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [ref, options]);

  return [ref, isVisible];
};

// Feature card component
const FeatureCard = ({ feature, index, navigate }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  const [ref, isVisible] = useIntersectionObserver({
    threshold: 0.2,
    rootMargin: '0px 0px -100px 0px',
  });

  // Staggered animation delay
  const animationDelay = `${0.2 + (index * 0.1)}s`;

  return (
    <Grid item xs={12} sm={6} md={4} ref={ref}>
      <Card 
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          borderRadius: 3,
          background: 'rgba(26, 35, 50, 0.7)',
          backdropFilter: 'blur(20px)',
          border: '1px solid rgba(255, 255, 255, 0.1)',
          boxShadow: '0 10px 30px rgba(0, 0, 0, 0.2)',
          overflow: 'visible',
          position: 'relative',
          transition: 'transform 0.3s ease, box-shadow 0.3s ease',
          opacity: isVisible ? 1 : 0,
          transform: isVisible ? 'translateY(0)' : 'translateY(30px)',
          transitionProperty: 'opacity, transform',
          transitionDuration: '0.6s',
          transitionTimingFunction: 'cubic-bezier(0.215, 0.61, 0.355, 1)',
          transitionDelay: animationDelay,
          '&:hover': {
            transform: 'translateY(-8px)',
            boxShadow: '0 15px 35px rgba(0, 0, 0, 0.25)',
            '& .featureIcon': {
              transform: 'scale(1.1)',
              color: feature.iconColor,
            },
            '& .learnMoreBtn': {
              opacity: 1,
              transform: 'translateX(0)',
            }
          },
        }}
      >
        {/* Feature Icon */}
        <Box
          className="featureIcon"
          sx={{
            position: 'absolute',
            top: '-25px',
            left: '30px',
            width: '60px',
            height: '60px',
            borderRadius: '50%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            background: 'linear-gradient(135deg, rgba(142, 45, 226, 0.8), rgba(74, 0, 224, 0.8))',
            boxShadow: '0 8px 20px rgba(74, 0, 224, 0.3)',
            color: 'white',
            transition: 'all 0.3s ease',
            zIndex: 2,
          }}
        >
          {feature.icon}
        </Box>

        <CardContent sx={{ pt: 5, pb: 2 }}>
          <Typography 
            variant="h5" 
            component="h3"
            sx={{ 
              mb: 1.5, 
              fontWeight: 600,
              color: '#fff',
            }}
          >
            {feature.title}
          </Typography>
          
          <Typography 
            variant="body2" 
            color="text.secondary"
            sx={{ 
              mb: 2,
              color: 'rgba(255, 255, 255, 0.7)',
              minHeight: isMobile ? 'auto' : '100px',
            }}
          >
            {feature.description}
          </Typography>
        </CardContent>

        <Box sx={{ p: 2, pt: 0, display: 'flex', justifyContent: 'flex-end' }}>
          <Button
            className="learnMoreBtn"
            endIcon={<ArrowForwardIcon />}
            sx={{
              color: 'primary.main',
              fontWeight: 500,
              opacity: 0.8,
              transform: 'translateX(-10px)',
              transition: 'all 0.3s ease',
              '&:hover': {
                background: 'rgba(142, 45, 226, 0.1)',
                transform: 'translateX(0)',
              }
            }}
            onClick={() => navigate(feature.path || '/features')}
          >
            Learn More
          </Button>
        </Box>
      </Card>
    </Grid>
  );
};

// Main Features component
const Features = () => {
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [sectionRef, isSectionVisible] = useIntersectionObserver({
    threshold: 0.1,
  });

  // Features data
  const features = [
    {
      icon: <CodeIcon sx={{ fontSize: 30 }} />,
      iconColor: '#a85bff',
      title: 'Custom Software Solutions',
      description:
        'Tailored software solutions designed specifically for your business challenges, optimized for performance, scalability, and security.',
      path: '/features'
    },
    {
      icon: <WebIcon sx={{ fontSize: 30 }} />,
      iconColor: '#61dafb',
      title: 'Modern Web Development',
      description:
        'Responsive, engaging websites and web applications that elevate your brand and provide seamless experiences across all devices.',
      path: '/features'
    },
    {
      icon: <BuildIcon sx={{ fontSize: 30 }} />,
      iconColor: '#ff9d00',
      title: 'Low-Code Development',
      description:
        'Accelerate your digital transformation with low-code solutions that enable rapid deployment and easy iterations to meet business needs.',
      path: '/features'
    },
    {
      icon: <SupportIcon sx={{ fontSize: 30 }} />,
      iconColor: '#4cd964',
      title: 'Dedicated Support & Maintenance',
      description:
        'Ongoing technical support and maintenance services that ensure your software remains secure, up-to-date, and aligned with business goals.',
      path: '/features'
    },
    {
      icon: <InsightsIcon sx={{ fontSize: 30 }} />,
      iconColor: '#5ac8fa',
      title: 'Data Analytics & Insights',
      description:
        'Transform raw data into actionable insights with powerful analytics, custom reports, and interactive visualization tools.',
      path: '/features'
    },
    {
      icon: <AnalyticsIcon sx={{ fontSize: 30 }} />,
      iconColor: '#34aadc',
      title: 'Process Improvement Consulting',
      description:
        'Expert consulting services to evaluate, optimize, and transform your business processes for greater efficiency and productivity.',
      path: '/consulting'
    },
  ];

  return (
    <Box
      id="features"
      sx={{
        py: { xs: 10, md: 12 },
        position: 'relative',
        background: 'linear-gradient(180deg, rgba(15, 32, 39, 0.95) 0%, rgba(32, 58, 67, 0.95) 100%)',
        borderTop: '1px solid rgba(255, 255, 255, 0.05)',
        borderBottom: '1px solid rgba(255, 255, 255, 0.05)',
      }}
      ref={sectionRef}
    >
      <Container maxWidth="xl">
        {/* Section Header */}
        <Box 
          sx={{ 
            textAlign: 'center', 
            mb: { xs: 6, md: 8 },
            opacity: isSectionVisible ? 1 : 0,
            transform: isSectionVisible ? 'translateY(0)' : 'translateY(30px)',
            transition: 'opacity 0.6s ease, transform 0.6s ease',
          }}
        >
          <Typography
            variant="overline"
            component="p"
            sx={{
              color: 'primary.main',
              fontWeight: 600,
              letterSpacing: 1.5,
              mb: 1,
              display: 'inline-block',
            }}
          >
            WHAT WE OFFER
          </Typography>
          
          <Typography
            variant="h2"
            component="h2"
            sx={{
              fontWeight: 700,
              mb: 2,
              background: 'linear-gradient(90deg, #fff, #bcc5d0)',
              backgroundClip: 'text',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              textFillColor: 'transparent',
            }}
          >
            Our Services
          </Typography>
          
          <Typography
            variant="subtitle1"
            sx={{
              color: 'text.secondary',
              maxWidth: '800px',
              mx: 'auto',
            }}
          >
            Comprehensive technology solutions customized to help your business 
            thrive in today's competitive digital landscape.
          </Typography>
        </Box>
        
        {/* Feature Cards */}
        <Grid 
          container 
          spacing={3}
          justifyContent="center"
        >
          {features.map((feature, index) => (
            <FeatureCard 
              key={feature.title} 
              feature={feature} 
              index={index}
              navigate={navigate}
            />
          ))}
        </Grid>
        
        {/* CTA Button */}
        <Box 
          sx={{ 
            textAlign: 'center', 
            mt: 6,
            opacity: isSectionVisible ? 1 : 0,
            transform: isSectionVisible ? 'translateY(0)' : 'translateY(30px)',
            transition: 'opacity 0.6s ease, transform 0.6s ease',
            transitionDelay: '0.6s',
          }}
        >
          <Button
            variant="contained"
            size="large"
            onClick={() => navigate('/features')}
            endIcon={<ArrowForwardIcon />}
            sx={{
              py: 1.5,
              px: 4,
              borderRadius: '50px',
              boxShadow: '0 10px 20px rgba(142, 45, 226, 0.2)',
              '&:hover': {
                boxShadow: '0 6px 15px rgba(142, 45, 226, 0.4)',
              },
            }}
          >
            Explore All Solutions
          </Button>
        </Box>
      </Container>
    </Box>
  );
};

export default Features;
// FeaturesPage.js
import React, { useRef, useEffect } from 'react';
import {
  Box,
  Typography,
  Container,
  Grid,
  Card,
  CardContent,
  Button,
  Divider,
  useTheme,
  useMediaQuery,
  Avatar,
  Paper
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CodeIcon from '@mui/icons-material/Code';
import WebIcon from '@mui/icons-material/Web';
import BuildIcon from '@mui/icons-material/Build';
import SupportIcon from '@mui/icons-material/Support';
import InsightsIcon from '@mui/icons-material/Insights';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
import ResearchIcon from '@mui/icons-material/FindInPage';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';

import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import BackgroundAnimation from '../components/BackgroundAnimation';

// Intersection Observer hook for animations
const useIntersectionObserver = (options) => {
  const ref = useRef(null);
  const [isVisible, setIsVisible] = React.useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setIsVisible(entry.isIntersecting);
    }, options);

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [ref, options]);

  return [ref, isVisible];
};

// FeatureCard component for reuse
const FeatureCard = ({ feature, index, isVisible }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
  
  // Animation delay
  const delay = `${0.1 + (index * 0.1)}s`;
  
  return (
    <Grid item xs={12} sm={6} lg={4}>
      <Card
        elevation={0}
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          background: 'rgba(26, 35, 50, 0.7)',
          backdropFilter: 'blur(10px)',
          borderRadius: 3,
          border: '1px solid rgba(255, 255, 255, 0.1)',
          transition: 'transform 0.3s ease, box-shadow 0.3s ease',
          opacity: isVisible ? 1 : 0,
          transform: isVisible ? 'translateY(0)' : 'translateY(30px)',
          transitionProperty: 'opacity, transform',
          transitionDuration: '0.6s',
          transitionTimingFunction: 'cubic-bezier(0.215, 0.61, 0.355, 1)',
          transitionDelay: delay,
          '&:hover': {
            transform: 'translateY(-8px)',
            boxShadow: '0 15px 35px rgba(0, 0, 0, 0.2)',
            '& .feature-icon': {
              background: feature.iconBg,
              transform: 'scale(1.1) rotate(10deg)',
            }
          },
        }}
      >
        <CardContent sx={{ p: 3, display: 'flex', flexDirection: 'column', height: '100%' }}>
          <Avatar
            className="feature-icon"
            sx={{
              width: 60,
              height: 60,
              backgroundColor: 'rgba(142, 45, 226, 0.15)',
              color: feature.iconColor,
              mb: 2,
              transition: 'all 0.3s ease',
            }}
          >
            {feature.icon}
          </Avatar>
          
          <Typography
            variant="h5"
            component="h3"
            sx={{
              fontWeight: 600,
              mb: 2,
              color: 'white',
            }}
          >
            {feature.title}
          </Typography>
          
          <Typography
            variant="body2"
            sx={{
              color: 'rgba(255, 255, 255, 0.7)',
              mb: 3,
              flex: 1,
            }}
          >
            {feature.description}
          </Typography>
          
          <Box sx={{ mt: 'auto' }}>
            <Divider sx={{ mb: 2, opacity: 0.1 }} />
            <Typography
              variant="caption"
              sx={{ 
                display: 'block', 
                color: 'primary.main',
                fontWeight: 500,
              }}
            >
              KEY BENEFITS
            </Typography>
            <Box 
              component="ul" 
              sx={{ 
                listStyle: 'none', 
                p: 0, 
                m: 0, 
                mt: 1,
              }}
            >
              {feature.benefits.map((benefit, i) => (
                <Box 
                  component="li" 
                  key={i}
                  sx={{ 
                    display: 'flex',
                    alignItems: 'center',
                    mb: 0.75,
                  }}
                >
                  <Box
                    sx={{
                      width: 6,
                      height: 6,
                      borderRadius: '50%',
                      bgcolor: 'primary.main',
                      mr: 1.5,
                    }}
                  />
                  <Typography 
                    variant="body2"
                    sx={{ 
                      color: 'rgba(255, 255, 255, 0.9)',
                      fontSize: '0.875rem',
                    }}
                  >
                    {benefit}
                  </Typography>
                </Box>
              ))}
            </Box>
          </Box>
        </CardContent>
      </Card>
    </Grid>
  );
};

// ProcessStep component for reuse
const ProcessStep = ({ step, index, isVisible }) => {
  // Animation delay
  const delay = `${0.2 + (index * 0.15)}s`;
  
  return (
    <Grid item xs={12} sm={6} md={3}>
      <Box
        sx={{
          position: 'relative',
          textAlign: 'center',
          opacity: isVisible ? 1 : 0,
          transform: isVisible ? 'translateY(0)' : 'translateY(40px)',
          transitionProperty: 'opacity, transform',
          transitionDuration: '0.6s',
          transitionTimingFunction: 'cubic-bezier(0.215, 0.61, 0.355, 1)',
          transitionDelay: delay,
        }}
      >
        <Box
          sx={{
            position: 'relative',
            zIndex: 1,
          }}
        >
          <Avatar
            sx={{
              width: 80,
              height: 80,
              backgroundColor: 'rgba(142, 45, 226, 0.15)',
              color: 'primary.main',
              margin: '0 auto 20px',
              border: '2px solid rgba(142, 45, 226, 0.3)',
              fontSize: '1.5rem',
              fontWeight: 700,
            }}
          >
            {index + 1}
          </Avatar>
          
          <Typography
            variant="h6"
            component="h3"
            sx={{
              fontWeight: 600,
              mb: 2,
              color: 'white',
            }}
          >
            {step.title}
          </Typography>
          
          <Typography
            variant="body2"
            sx={{
              color: 'rgba(255, 255, 255, 0.7)',
            }}
          >
            {step.description}
          </Typography>
        </Box>
        
        {index < 3 && (
          <Box
            sx={{
              position: 'absolute',
              top: 40,
              right: { xs: 'auto', sm: -30 },
              left: { xs: '50%', sm: 'auto' },
              width: { xs: 2, sm: 60 },
              height: { xs: 30, sm: 2 },
              bgcolor: 'rgba(142, 45, 226, 0.3)',
              display: { xs: 'none', sm: 'block' },
              zIndex: 0,
            }}
          />
        )}
      </Box>
    </Grid>
  );
};

// Main FeaturesPage component
const FeaturesPage = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  
  const [heroRef, isHeroVisible] = useIntersectionObserver({ threshold: 0.1 });
  const [featuresRef, isFeaturesVisible] = useIntersectionObserver({ threshold: 0.1 });
  const [processRef, isProcessVisible] = useIntersectionObserver({ threshold: 0.1 });
  const [enterpriseRef, isEnterpriseVisible] = useIntersectionObserver({ threshold: 0.1 });
  
  // Features data
  const featuresData = [
    {
      icon: <CodeIcon fontSize="large" />,
      iconColor: '#a85bff',
      iconBg: 'linear-gradient(135deg, rgba(168, 91, 255, 0.2), rgba(142, 45, 226, 0.2))',
      title: 'Custom Software Development',
      description: 'Tailored solutions designed to address your specific business challenges. Our custom software applications optimize operations, enhance productivity, and drive growth.',
      benefits: [
        'Scalable architecture',
        'Unique to your business needs',
        'Streamlined workflows',
        'Improved operational efficiency'
      ]
    },
    {
      icon: <WebIcon fontSize="large" />,
      iconColor: '#61dafb',
      iconBg: 'linear-gradient(135deg, rgba(97, 218, 251, 0.2), rgba(0, 156, 204, 0.2))',
      title: 'Web Development',
      description: 'Responsive, modern websites and web applications that deliver exceptional user experiences. From corporate sites to complex web platforms, we build solutions that impress.',
      benefits: [
        'Responsive design',
        'SEO-friendly structure',
        'Fast loading times',
        'Cross-browser compatibility'
      ]
    },
    {
      icon: <BuildIcon fontSize="large" />,
      iconColor: '#ff9d00',
      iconBg: 'linear-gradient(135deg, rgba(255, 157, 0, 0.2), rgba(242, 113, 33, 0.2))',
      title: 'Low-Code Development',
      description: 'Accelerate your digital transformation with low-code platforms that reduce development time and costs. Build enterprise-grade applications without extensive coding.',
      benefits: [
        'Rapid deployment',
        'Lower development costs',
        'Easy maintenance',
        'Quick iterations'
      ]
    },
    {
      icon: <SupportIcon fontSize="large" />,
      iconColor: '#4cd964',
      iconBg: 'linear-gradient(135deg, rgba(76, 217, 100, 0.2), rgba(46, 184, 68, 0.2))',
      title: 'Technical Support & Maintenance',
      description: 'Comprehensive support services that ensure your software remains operational, secure, and up-to-date. Our team is available to resolve issues and implement enhancements.',
      benefits: [
        '24/7 technical assistance',
        'Regular updates',
        'Performance monitoring',
        'System optimization'
      ]
    },
    {
      icon: <InsightsIcon fontSize="large" />,
      iconColor: '#5ac8fa',
      iconBg: 'linear-gradient(135deg, rgba(90, 200, 250, 0.2), rgba(0, 122, 255, 0.2))',
      title: 'Data Analytics & Intelligence',
      description: 'Transform raw data into actionable insights with our analytics solutions. Leverage business intelligence tools to make informed decisions and identify growth opportunities.',
      benefits: [
        'Custom dashboards',
        'Real-time analytics',
        'Predictive analysis',
        'Data visualization'
      ]
    },
    {
      icon: <AnalyticsIcon fontSize="large" />,
      iconColor: '#34aadc',
      iconBg: 'linear-gradient(135deg, rgba(52, 170, 220, 0.2), rgba(0, 122, 255, 0.2))',
      title: 'Process Improvement Consulting',
      description: 'Expert consulting to identify inefficiencies and implement process improvements. We analyze your workflows and suggest data-driven solutions to optimize your operations.',
      benefits: [
        'Workflow optimization',
        'Efficiency analysis',
        'Change management',
        'Continuous improvement'
      ]
    },
    {
      icon: <ResearchIcon fontSize="large" />,
      iconColor: '#ff2d55',
      iconBg: 'linear-gradient(135deg, rgba(255, 45, 85, 0.2), rgba(204, 0, 45, 0.2))',
      title: 'Project Research',
      description: 'Comprehensive research services to evaluate new ideas and opportunities. We provide the data and insights you need to make informed decisions about potential projects.',
      benefits: [
        'Market analysis',
        'Feasibility studies',
        'Competitive research',
        'Risk assessment'
      ]
    },
    {
      icon: <IntegrationInstructionsIcon fontSize="large" />,
      iconColor: '#ff9500',
      iconBg: 'linear-gradient(135deg, rgba(255, 149, 0, 0.2), rgba(255, 69, 0, 0.2))',
      title: 'Integration Services',
      description: 'Seamless integration of new systems with your existing infrastructure. We ensure that all your technologies work together efficiently to support your business goals.',
      benefits: [
        'API development',
        'Legacy system integration',
        'Third-party connections',
        'Data migration'
      ]
    },
  ];
  
  // Process steps data
  const processSteps = [
    {
      title: 'Discovery & Strategy',
      description: 'We analyze your business needs and define a clear roadmap for your solution.'
    },
    {
      title: 'Design & Prototyping',
      description: 'Our team creates intuitive interfaces and workflow designs for your approval.'
    },
    {
      title: 'Development & Testing',
      description: 'We build your solution with rigorous quality assurance at every stage.'
    },
    {
      title: 'Deployment & Support',
      description: 'Your solution goes live with continuous maintenance and enhancement.'
    },
  ];
  
  // Enterprise solutions data
  const enterpriseSolutions = [
    {
      title: 'Process Analysis & Improvement',
      description: 'Identify bottlenecks and optimize workflows for maximum efficiency.'
    },
    {
      title: 'Custom Integration Solutions',
      description: 'Connect disparate systems and create seamless data flow across your organization.'
    },
    {
      title: 'Project Research & Validation',
      description: 'Evaluate new ideas and initiatives with thorough research and analysis.'
    },
    {
      title: 'Business Intelligence',
      description: 'Transform data into actionable insights for strategic decisions.'
    },
  ];

  // Helper function to convert hex color to rgb
  function hexToRgb(hex) {
    // Remove the hash if it exists
    hex = hex.replace('#', '');
    
    // Parse the hex values
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);
    
    // Return the RGB values as a string
    return `${r}, ${g}, ${b}`;
  }

  return (
    <Box sx={{ backgroundColor: '#0f2027', minHeight: '100vh', color: '#FFFFFF' }}>
      {/* NavBar Component */}
      <NavBar />
      
      {/* Hero Section */}
      <Box 
        ref={heroRef}
        sx={{
          position: 'relative',
          pt: { xs: 10, md: 16 },
          pb: { xs: 10, md: 16 },
          overflow: 'hidden',
        }}
      >
        <BackgroundAnimation />
        
        <Container maxWidth="lg">
          <Grid container spacing={6} alignItems="center">
            <Grid item xs={12} md={7}
              sx={{
                opacity: isHeroVisible ? 1 : 0,
                transform: isHeroVisible ? 'translateY(0)' : 'translateY(30px)',
                transition: 'opacity 0.6s ease, transform 0.6s ease',
              }}
            >
              <Typography
                variant="overline"
                component="p"
                sx={{
                  color: 'primary.main',
                  fontWeight: 600,
                  letterSpacing: 1.5,
                  mb: 1,
                }}
              >
                OUR SERVICES
              </Typography>
              
              <Typography
                variant="h1"
                component="h1"
                sx={{
                  fontWeight: 800,
                  mb: 2,
                  fontSize: { xs: '2.5rem', sm: '3rem', md: '3.5rem' },
                  lineHeight: 1.2,
                  background: 'linear-gradient(90deg, #fff, #bcc5d0)',
                  backgroundClip: 'text',
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                  textFillColor: 'transparent',
                }}
              >
                Comprehensive Software Solutions
              </Typography>
              
              <Typography
                variant="h6"
                component="p"
                sx={{
                  color: 'text.secondary',
                  mb: 4,
                  maxWidth: '600px',
                }}
              >
                Discover our full range of services designed to transform your business
                through innovative technology and expert implementation.
              </Typography>
              
            
            <Button
              variant="contained"
              size="large"
              endIcon={<ArrowForwardIcon />}
              onClick={() => navigate('/#contact')}
              sx={{
                py: 1.5,
                px: 4,
                borderRadius: '50px',
                fontSize: '1rem',
                boxShadow: '0 10px 20px rgba(142, 45, 226, 0.3)',
                '&:hover': {
                  boxShadow: '0 6px 15px rgba(142, 45, 226, 0.5)',
                },
              }}
            >
              Get Started
            </Button>
            </Grid>
            
            <Grid item xs={12} md={5}
              sx={{
                opacity: isHeroVisible ? 1 : 0,
                transform: isHeroVisible ? 'translateX(0)' : 'translateX(30px)',
                transition: 'opacity 0.6s ease, transform 0.6s ease',
                transitionDelay: '0.2s',
                display: { xs: 'none', md: 'block' },
              }}
            >
              {/* Abstract design element */}
              <Box
                sx={{
                  position: 'relative',
                  height: 400,
                  width: '100%',
                }}
              >
                {/* Main circle */}
                <Box
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 300,
                    height: 300,
                    borderRadius: '50%',
                    background: 'radial-gradient(circle, rgba(142, 45, 226, 0.3) 0%, rgba(74, 0, 224, 0.1) 70%)',
                    animation: 'pulse 4s infinite ease-in-out',
                    '@keyframes pulse': {
                      '0%, 100%': { transform: 'translate(-50%, -50%) scale(1)' },
                      '50%': { transform: 'translate(-50%, -50%) scale(1.05)' },
                    },
                  }}
                />
                
                {/* Orbiting elements */}
                {[...Array(6)].map((_, index) => {
                  const angle = (index * 60) * Math.PI / 180;
                  const distance = 180;
                  const x = Math.cos(angle) * distance;
                  const y = Math.sin(angle) * distance;
                  
                  return (
                    <Box
                      key={index}
                      sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: 60,
                        height: 60,
                        borderRadius: index % 2 ? '20%' : '50%',
                        background: index % 3 === 0 
                          ? 'linear-gradient(135deg, rgba(142, 45, 226, 0.6), rgba(74, 0, 224, 0.4))'
                          : index % 3 === 1
                            ? 'linear-gradient(135deg, rgba(97, 218, 251, 0.6), rgba(0, 156, 204, 0.4))'
                            : 'linear-gradient(135deg, rgba(76, 217, 100, 0.6), rgba(46, 184, 68, 0.4))',
                        animation: `orbit${index + 1} ${8 + index}s linear infinite`,
                        '@keyframes orbit1': {
                          '0%': { transform: `translate(calc(-50% + ${x}px), calc(-50% + ${y}px))` },
                          '100%': { transform: `translate(calc(-50% + ${x}px), calc(-50% + ${y}px)) rotate(360deg)` },
                        },
                        '@keyframes orbit2': {
                          '0%': { transform: `translate(calc(-50% + ${x}px), calc(-50% + ${y}px))` },
                          '100%': { transform: `translate(calc(-50% + ${x}px), calc(-50% + ${y}px)) rotate(-360deg)` },
                        },
                        '@keyframes orbit3': {
                          '0%': { transform: `translate(calc(-50% + ${x}px), calc(-50% + ${y}px))` },
                          '100%': { transform: `translate(calc(-50% + ${x}px), calc(-50% + ${y}px)) rotate(360deg)` },
                        },
                        '@keyframes orbit4': {
                          '0%': { transform: `translate(calc(-50% + ${x}px), calc(-50% + ${y}px))` },
                          '100%': { transform: `translate(calc(-50% + ${x}px), calc(-50% + ${y}px)) rotate(-360deg)` },
                        },
                        '@keyframes orbit5': {
                          '0%': { transform: `translate(calc(-50% + ${x}px), calc(-50% + ${y}px))` },
                          '100%': { transform: `translate(calc(-50% + ${x}px), calc(-50% + ${y}px)) rotate(360deg)` },
                        },
                        '@keyframes orbit6': {
                          '0%': { transform: `translate(calc(-50% + ${x}px), calc(-50% + ${y}px))` },
                          '100%': { transform: `translate(calc(-50% + ${x}px), calc(-50% + ${y}px)) rotate(-360deg)` },
                        },
                      }}
                    />
                  );
                })}
                
                {/* Central icon */}
                <Box
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 100,
                    height: 100,
                    borderRadius: '50%',
                    background: 'linear-gradient(135deg, #8e2de2, #4a00e0)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    boxShadow: '0 0 30px rgba(142, 45, 226, 0.5)',
                    zIndex: 2,
                  }}
                >
                  <CodeIcon sx={{ fontSize: 50, color: 'white' }} />
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      
      {/* Our Services Section */}
      <Box
        ref={featuresRef}
        sx={{
          py: { xs: 10, md: 12 },
          background: 'rgba(15, 32, 39, 0.8)',
          position: 'relative',
        }}
      >
        <Container maxWidth="lg">
          <Box 
            sx={{ 
              textAlign: 'center', 
              mb: { xs: 6, md: 8 },
              opacity: isFeaturesVisible ? 1 : 0,
              transform: isFeaturesVisible ? 'translateY(0)' : 'translateY(30px)',
              transition: 'opacity 0.6s ease, transform 0.6s ease',
            }}
          >
            <Typography
              variant="h2"
              component="h2"
              sx={{
                fontWeight: 700,
                mb: 2,
                background: 'linear-gradient(90deg, #fff, #bcc5d0)',
                backgroundClip: 'text',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                textFillColor: 'transparent',
              }}
            >
              Our Services
            </Typography>
            
            <Typography
              variant="subtitle1"
              sx={{
                color: 'text.secondary',
                maxWidth: '800px',
                mx: 'auto',
              }}
            >
              From innovative software solutions to expert consulting services, we offer comprehensive 
              services to address every aspect of your business technology needs.
            </Typography>
          </Box>
          
          <Grid container spacing={4}>
            {featuresData.map((feature, index) => (
              <FeatureCard 
                key={feature.title} 
                feature={feature} 
                index={index} 
                isVisible={isFeaturesVisible}
              />
            ))}
          </Grid>
        </Container>
      </Box>
      
      {/* Our Process Section */}
      <Box
        ref={processRef}
        sx={{
          py: { xs: 10, md: 12 },
          background: 'linear-gradient(180deg, rgba(32, 58, 67, 0.8) 0%, rgba(15, 32, 39, 0.8) 100%)',
          position: 'relative',
        }}
      >
        <Container maxWidth="lg">
          <Box 
            sx={{ 
              textAlign: 'center', 
              mb: { xs: 8, md: 10 },
              opacity: isProcessVisible ? 1 : 0,
              transform: isProcessVisible ? 'translateY(0)' : 'translateY(30px)',
              transition: 'opacity 0.6s ease, transform 0.6s ease',
            }}
          >
            <Typography
              variant="h2"
              component="h2"
              sx={{
                fontWeight: 700,
                mb: 2,
                background: 'linear-gradient(90deg, #fff, #bcc5d0)',
                backgroundClip: 'text',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                textFillColor: 'transparent',
              }}
            >
              Our Development Process
            </Typography>
            
            <Typography
              variant="subtitle1"
              sx={{
                color: 'text.secondary',
                maxWidth: '800px',
                mx: 'auto',
              }}
            >
              A transparent, collaborative approach that keeps you informed and involved
              at every stage of your project's development.
            </Typography>
          </Box>
          
          <Grid container spacing={4} sx={{ mb: 8 }}>
            {processSteps.map((step, index) => (
              <ProcessStep 
                key={step.title} 
                step={step} 
                index={index} 
                isVisible={isProcessVisible}
              />
            ))}
          </Grid>
          
          {/* Process Details */}
          <Grid 
            container 
            spacing={4}
            sx={{
              opacity: isProcessVisible ? 1 : 0,
              transform: isProcessVisible ? 'translateY(0)' : 'translateY(30px)',
              transition: 'opacity 0.6s ease, transform 0.6s ease',
              transitionDelay: '0.5s',
            }}
          >
            <Grid item xs={12} md={6}>
              <Paper
                elevation={0}
                sx={{
                  p: 4,
                  height: '100%',
                  background: 'rgba(142, 45, 226, 0.1)',
                  backdropFilter: 'blur(10px)',
                  borderRadius: 3,
                  border: '1px solid rgba(142, 45, 226, 0.2)',
                }}
              >
                <Typography 
                  variant="h5" 
                  component="h3" 
                  sx={{ 
                    fontWeight: 600, 
                    mb: 3,
                    color: 'primary.light',
                  }}
                >
                  Why Our Process Works
                </Typography>
                
                <Grid container spacing={2}>
                  {[
                    {
                      title: 'Collaborative Approach',
                      description: 'We work closely with your team at every stage, ensuring your vision is captured and implemented correctly.'
                    },
                    {
                      title: 'Agile Methodology',
                      description: 'Our iterative approach allows for flexibility, continuous feedback, and faster delivery of working software.'
                    },
                    {
                      title: 'Transparent Communication',
                      description: 'Regular updates and clear communications keep you informed about progress and any potential challenges.'
                    },
                    {
                      title: 'Quality Assurance',
                      description: 'Rigorous testing at every stage ensures your solution is robust, secure, and performs as expected.'
                    },
                  ].map((item, index) => (
                    <Grid item xs={12} key={index}>
                      <Box sx={{ display: 'flex', mb: 2 }}>
                        <Box
                          sx={{
                            width: 30,
                            height: 30,
                            borderRadius: '50%',
                            bgcolor: 'rgba(142, 45, 226, 0.15)',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            mr: 2,
                            flexShrink: 0,
                          }}
                        >
                          <Typography
                            variant="subtitle2"
                            sx={{
                              color: 'primary.main',
                              fontWeight: 600,
                            }}
                          >
                            {index + 1}
                          </Typography>
                        </Box>
                        
                        <Box>
                          <Typography
                            variant="subtitle1"
                            sx={{
                              fontWeight: 600,
                              mb: 0.5,
                            }}
                          >
                            {item.title}
                          </Typography>
                          
                          <Typography
                            variant="body2"
                            sx={{
                              color: 'rgba(255, 255, 255, 0.7)',
                            }}
                          >
                            {item.description}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
              </Paper>
            </Grid>
            
            <Grid item xs={12} md={6}>
              <Paper
                elevation={0}
                sx={{
                  p: 4,
                  height: '100%',
                  background: 'rgba(26, 35, 50, 0.7)',
                  backdropFilter: 'blur(10px)',
                  borderRadius: 3,
                  border: '1px solid rgba(255, 255, 255, 0.1)',
                }}
              >
                <Typography 
                  variant="h5" 
                  component="h3" 
                  sx={{ 
                    fontWeight: 600, 
                    mb: 3,
                  }}
                >
                  What You Can Expect
                </Typography>
                
                <Box
                  component="ul"
                  sx={{
                    listStyle: 'none',
                    p: 0,
                    m: 0,
                  }}
                >
                  {[
                    'Initial consultation to understand your business needs',
                    'Detailed project proposal with timeline and cost estimates',
                    'Regular progress updates and milestone reviews',
                    'Rigorous quality assurance and testing procedures',
                    'Comprehensive documentation and knowledge transfer',
                    'Smooth deployment and launch support',
                    'Ongoing maintenance and technical assistance',
                    'Future enhancement recommendations and planning',
                  ].map((item, index) => (
                    <Box
                      component="li"
                      key={index}
                      sx={{
                        display: 'flex',
                        alignItems: 'flex-start',
                        mb: 2.5,
                      }}
                    >
                      <Box
                        sx={{
                          width: 20,
                          height: 20,
                          borderRadius: '50%',
                          bgcolor: 'rgba(142, 45, 226, 0.15)',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          mr: 2,
                          mt: 0.5,
                          flexShrink: 0,
                          '&::before': {
                            content: '""',
                            width: 8,
                            height: 8,
                            borderRadius: '50%',
                            bgcolor: 'primary.main',
                          },
                        }}
                      />
                      
                      <Typography
                        variant="body1"
                        sx={{
                          color: 'rgba(255, 255, 255, 0.8)',
                        }}
                      >
                        {item}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Box>
      
      {/* Enterprise Solutions Section */}
      <Box
        ref={enterpriseRef}
        sx={{
          py: { xs: 10, md: 12 },
          background: 'rgba(15, 32, 39, 0.8)',
          position: 'relative',
        }}
      >
        <Container maxWidth="lg">
          <Grid container spacing={6} alignItems="center">
            <Grid item xs={12} md={6}
              sx={{
                opacity: isEnterpriseVisible ? 1 : 0,
                transform: isEnterpriseVisible ? 'translateX(0)' : 'translateX(-30px)',
                transition: 'opacity 0.6s ease, transform 0.6s ease',
              }}
            >
              <Typography
                variant="overline"
                component="p"
                sx={{
                  color: 'primary.main',
                  fontWeight: 600,
                  letterSpacing: 1.5,
                  mb: 1,
                }}
              >
                BUSINESS SOLUTIONS
              </Typography>
              
              <Typography
                variant="h3"
                component="h2"
                sx={{
                  fontWeight: 700,
                  mb: 3,
                  background: 'linear-gradient(90deg, #fff, #bcc5d0)',
                  backgroundClip: 'text',
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                  textFillColor: 'transparent',
                }}
              >
                Powerful Solutions for Business Challenges
              </Typography>
              
              <Typography
                variant="body1"
                sx={{
                  color: 'text.secondary',
                  mb: 4,
                }}
              >
                Our business solutions are designed to address complex challenges and transform how 
                your organization operates. From optimizing processes to validating new initiatives, 
                we provide the expertise and tools needed to drive innovation and efficiency.
              </Typography>
              
              <Grid container spacing={3}>
                {enterpriseSolutions.map((solution, index) => (
                  <Grid item xs={12} sm={6} key={index}>
                    <Box sx={{ display: 'flex', mb: 2 }}>
                      <Box
                        sx={{
                          width: 50,
                          height: 50,
                          borderRadius: '12px',
                          bgcolor: 'rgba(142, 45, 226, 0.15)',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          mr: 2,
                          flexShrink: 0,
                        }}
                      >
                        <Typography
                          variant="subtitle1"
                          sx={{
                            color: 'primary.main',
                            fontWeight: 600,
                          }}
                        >
                          0{index + 1}
                        </Typography>
                      </Box>
                      
                      <Box>
                        <Typography
                          variant="subtitle1"
                          sx={{
                            fontWeight: 600,
                            mb: 0.5,
                          }}
                        >
                          {solution.title}
                        </Typography>
                        
                        <Typography
                          variant="body2"
                          sx={{
                            color: 'rgba(255, 255, 255, 0.7)',
                          }}
                        >
                          {solution.description}
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                ))}
              </Grid>
              
              <Button
                variant="contained"
                size="large"
                onClick={() => navigate('/consulting')}
                sx={{
                  mt: 4,
                  py: 1.5,
                  px: 4,
                  borderRadius: '50px',
                  fontSize: '1rem',
                  boxShadow: '0 10px 20px rgba(142, 45, 226, 0.3)',
                  '&:hover': {
                    boxShadow: '0 6px 15px rgba(142, 45, 226, 0.5)',
                  },
                }}
              >
                Explore Our Consulting Services
              </Button>
            </Grid>
            
            <Grid item xs={12} md={6}
              sx={{
                opacity: isEnterpriseVisible ? 1 : 0,
                transform: isEnterpriseVisible ? 'translateX(0)' : 'translateX(30px)',
                transition: 'opacity 0.6s ease, transform 0.6s ease',
                transitionDelay: '0.2s',
              }}
            >
              <Box
                sx={{
                  width: '100%',
                  position: 'relative',
                  borderRadius: 3,
                  overflow: 'hidden',
                  background: 'rgba(26, 35, 50, 0.5)',
                  backdropFilter: 'blur(10px)',
                  border: '1px solid rgba(255, 255, 255, 0.1)',
                  p: 3,
                  boxShadow: '0 15px 35px rgba(0, 0, 0, 0.2)',
                }}
              >
                <Grid container spacing={3}>
                  {[
                    {
                      title: 'Efficiency',
                      value: '+40%',
                      description: 'Average improvement in process efficiency',
                      color: '#a85bff',
                    },
                    {
                      title: 'Research',
                      value: '100%',
                      description: 'Data-driven project validation and analysis',
                      color: '#61dafb',
                    },
                    {
                      title: 'Integration',
                      value: 'Seamless',
                      description: 'Connection between all your business systems',
                      color: '#ff9d00',
                    },
                    {
                      title: 'Support',
                      value: 'Ongoing',
                      description: 'Continuous improvement and consultation',
                      color: '#4cd964',
                    },
                  ].map((item, index) => (
                    <Grid item xs={12} sm={6} key={index}>
                      <Box
                        sx={{
                          p: 3,
                          borderRadius: 2,
                          background: `rgba(${hexToRgb(item.color)}, 0.1)`,
                          border: `1px solid rgba(${hexToRgb(item.color)}, 0.3)`,
                          height: '100%',
                        }}
                      >
                        <Typography
                          variant="overline"
                          sx={{
                            color: item.color,
                            fontWeight: 600,
                          }}
                        >
                          {item.title}
                        </Typography>
                        
                        <Typography
                          variant="h4"
                          sx={{
                            fontWeight: 700,
                            my: 1,
                          }}
                        >
                          {item.value}
                        </Typography>
                        
                        <Typography
                          variant="body2"
                          sx={{
                            color: 'rgba(255, 255, 255, 0.7)',
                          }}
                        >
                          {item.description}
                        </Typography>
                      </Box>
                    </Grid>
                  ))}
                </Grid>
                
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    mt: 3,
                    p: 3,
                    borderRadius: 2,
                    background: 'rgba(142, 45, 226, 0.1)',
                    border: '1px solid rgba(142, 45, 226, 0.2)',
                  }}
                >
                  <Typography variant="body1" textAlign="center">
                    "Our business solutions are designed to identify opportunities for improvement and implement
                    changes that drive real results. We work with you to understand your specific challenges and 
                    deliver customized solutions that enhance your competitive advantage."
                  </Typography>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      
      {/* CTA Section */}
      <Box
        sx={{
          py: { xs: 8, md: 10 },
          position: 'relative',
          background: 'linear-gradient(135deg, rgba(142, 45, 226, 0.2), rgba(74, 0, 224, 0.2))',
          backdropFilter: 'blur(10px)',
        }}
      >
        <Container maxWidth="md">
          <Box
            sx={{
              textAlign: 'center',
              background: 'rgba(26, 35, 50, 0.7)',
              backdropFilter: 'blur(20px)',
              borderRadius: 3,
              border: '1px solid rgba(255, 255, 255, 0.1)',
              p: { xs: 4, md: 6 },
            }}
          >
            <Typography
              variant="h3"
              component="h2"
              sx={{
                fontWeight: 700,
                mb: 2,
              }}
            >
              Ready to Transform Your Business?
            </Typography>
            
            <Typography
              variant="subtitle1"
              sx={{
                color: 'text.secondary',
                mb: 4,
                maxWidth: '800px',
                mx: 'auto',
              }}
            >
              Contact us today to discuss your project needs and discover how our solutions
              can help you achieve your business goals.
            </Typography>
            
            <Button
              variant="contained"
              size="large"
              onClick={() => {
                const contactSection = document.getElementById('contact');
                if (contactSection) {
                  contactSection.scrollIntoView({ behavior: 'smooth', block: 'start' });
                } else {
                  navigate('/#contact');
                }
              }}
              sx={{
                py: 1.5,
                px: 4,
                borderRadius: '50px',
                fontSize: '1.1rem',
                boxShadow: '0 10px 20px rgba(142, 45, 226, 0.3)',
                '&:hover': {
                  boxShadow: '0 6px 15px rgba(142, 45, 226, 0.5)',
                },
              }}
            >
              Start Your Project
            </Button>
          </Box>
        </Container>
      </Box>
      
      {/* Footer */}
      <Footer />
    </Box>
  );
};

export default FeaturesPage;
// FAQ.js
import React, { useState, useRef, useEffect } from 'react';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  Box,
  Container,
  Grid,
  useTheme,
  Button
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChatIcon from '@mui/icons-material/Chat';
import { styled } from '@mui/material/styles';

// Custom styled Accordion
const StyledAccordion = styled(Accordion)(({ theme }) => ({
  background: 'rgba(26, 35, 50, 0.7)',
  backdropFilter: 'blur(10px)',
  color: theme.palette.text.primary,
  borderRadius: '12px !important',
  marginBottom: '16px',
  border: '1px solid rgba(255, 255, 255, 0.1)',
  overflow: 'hidden',
  transition: 'all 0.3s ease',
  
  '&::before': {
    display: 'none',
  },
  
  '&.Mui-expanded': {
    margin: '0 0 16px 0',
    boxShadow: '0 10px 30px rgba(0, 0, 0, 0.15)',
    borderColor: 'rgba(142, 45, 226, 0.3)',
  },
  
  '&:hover': {
    borderColor: 'rgba(142, 45, 226, 0.3)',
  }
}));

// Custom styled AccordionSummary
const StyledAccordionSummary = styled(AccordionSummary)(({ theme }) => ({
  padding: '16px 24px',
  
  '& .MuiAccordionSummary-content': {
    margin: '8px 0',
  },
  
  '&.Mui-expanded': {
    backgroundColor: 'rgba(142, 45, 226, 0.08)',
    borderBottom: '1px solid rgba(255, 255, 255, 0.05)',
  },
  
  '&:hover': {
    backgroundColor: 'rgba(142, 45, 226, 0.05)',
  },
  
  '& .MuiSvgIcon-root': {
    color: theme.palette.primary.main,
    transition: 'transform 0.3s ease',
  },
  
  '&.Mui-expanded .MuiSvgIcon-root': {
    transform: 'rotate(180deg)',
  }
}));

// Intersection Observer hook for animations
const useIntersectionObserver = (options) => {
  const ref = useRef(null);
  const [isVisible, setIsVisible] = React.useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setIsVisible(entry.isIntersecting);
    }, options);

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [ref, options]);

  return [ref, isVisible];
};

const FAQ = () => {
  const theme = useTheme();
  const [expanded, setExpanded] = useState(false);
  const [sectionRef, isSectionVisible] = useIntersectionObserver({
    threshold: 0.1,
  });

  // FAQ data
  const faqData = [
    {
      question: 'What industries do you specialize in?',
      answer: 'We provide custom software solutions for a wide range of industries including finance, healthcare, retail, education, manufacturing, and logistics. Our team has experience working with businesses of all sizes, from startups to enterprise-level organizations. We adapt our approach to meet the specific requirements and compliance standards of each industry.',
    },
    {
      question: 'How does low-code development benefit my business?',
      answer: 'Low-code development dramatically accelerates the time to market for your digital solutions while reducing development costs. It allows for rapid prototyping and iteration, giving your business the agility to adapt quickly to changing market demands or customer needs. Our low-code solutions maintain enterprise-grade quality and security while offering the flexibility to customize functionality as your business grows.',
    },
    {
      question: 'What kind of support do you offer after project completion?',
      answer: 'We provide comprehensive post-launch support including regular maintenance, performance monitoring, security updates, and feature enhancements. Our support packages can be tailored to your specific needs, from basic technical assistance to full managed services. Were committed to the ongoing success of your software solution and offer flexible SLAs to ensure your business operations continue smoothly.',
    },
    {
      question: 'How do you ensure the security of the solutions you provide?',
      answer: 'Security is integrated into every stage of our development process. We implement industry-leading security measures including encrypted data transmission, secure authentication protocols, regular vulnerability assessments, and penetration testing. Our team stays updated on the latest security threats and best practices, ensuring your solution remains protected against emerging risks. We also provide documentation and training on security best practices for your team.',
    },
    {
      question: 'What is your typical project timeline?',
      answer: 'Project timelines vary based on scope and complexity, but we follow an agile methodology that delivers value incrementally. Simple projects may take 4-8 weeks, while more complex enterprise solutions can take 3-6 months. During our initial discovery phase, we will provide a detailed timeline with key milestones. Our approach ensures you will see progress throughout the development cycle, not just at the end.',
    },
    {
      question: 'Do you work with existing systems or only build new solutions?',
      answer: 'We excel at both creating new solutions and integrating with or enhancing existing systems. Our team has extensive experience working with legacy technologies, APIs, and third-party services. We can modernize your current infrastructure, build custom integrations, or develop entirely new solutions - all based on what delivers the most value for your business goals and budget.',
    },
  ];

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Box
      id="faq"
      sx={{
        py: { xs: 10, md: 12 },
        background: 'linear-gradient(180deg, rgba(15, 32, 39, 0.95) 0%, rgba(32, 58, 67, 0.95) 100%)',
        position: 'relative',
      }}
      ref={sectionRef}
    >
      <Container maxWidth="lg">
        <Grid container spacing={6} alignItems="center">
          {/* FAQ Header Section */}
          <Grid item xs={12} md={5}
            sx={{
              opacity: isSectionVisible ? 1 : 0,
              transform: isSectionVisible ? 'translateY(0)' : 'translateY(30px)',
              transition: 'opacity 0.6s ease, transform 0.6s ease',
            }}
          >
            <Typography
              variant="overline"
              component="p"
              sx={{
                color: 'primary.main',
                fontWeight: 600,
                letterSpacing: 1.5,
                mb: 1,
              }}
            >
              HAVE QUESTIONS?
            </Typography>
            
            <Typography
              variant="h2"
              component="h2"
              sx={{
                fontWeight: 700,
                mb: 3,
                background: 'linear-gradient(90deg, #fff, #bcc5d0)',
                backgroundClip: 'text',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                textFillColor: 'transparent',
              }}
            >
              Frequently Asked Questions
            </Typography>
            
            <Typography
              variant="body1"
              sx={{
                color: 'text.secondary',
                mb: 4,
                lineHeight: 1.7,
              }}
            >
              Find answers to common questions about our services, process, and solutions. If you don't see your question here, feel free to contact us directly.
            </Typography>
            
            <Button
              variant="contained"
              color="primary"
              startIcon={<ChatIcon />}
              onClick={() => document.getElementById('contact').scrollIntoView({ behavior: 'smooth' })}
              sx={{
                py: 1.5,
                px: 3,
                borderRadius: '50px',
                boxShadow: '0 8px 20px rgba(142, 45, 226, 0.3)',
                '&:hover': {
                  boxShadow: '0 6px 15px rgba(142, 45, 226, 0.5)',
                },
              }}
            >
              Ask Us Anything
            </Button>
          </Grid>
          
          {/* FAQ Accordion Section */}
          <Grid item xs={12} md={7}>
            <Box>
              {faqData.map((item, index) => (
                <StyledAccordion
                  key={index}
                  expanded={expanded === `panel${index}`}
                  onChange={handleChange(`panel${index}`)}
                  disableGutters
                  sx={{
                    opacity: isSectionVisible ? 1 : 0,
                    transform: isSectionVisible ? 'translateX(0)' : 'translateX(30px)',
                    transition: 'opacity 0.6s ease, transform 0.6s ease',
                    transitionDelay: `${0.1 + (index * 0.1)}s`,
                  }}
                >
                  <StyledAccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls={`panel${index}-content`}
                    id={`panel${index}-header`}
                  >
                    <Typography 
                      variant="h6" 
                      sx={{ 
                        fontWeight: expanded === `panel${index}` ? 600 : 500,
                        transition: 'all 0.3s ease',
                        color: expanded === `panel${index}` ? 'primary.light' : 'text.primary',
                      }}
                    >
                      {item.question}
                    </Typography>
                  </StyledAccordionSummary>
                  <AccordionDetails sx={{ p: 3 }}>
                    <Typography 
                      variant="body1" 
                      sx={{ 
                        color: 'text.secondary',
                        lineHeight: 1.7,
                      }}
                    >
                      {item.answer}
                    </Typography>
                  </AccordionDetails>
                </StyledAccordion>
              ))}
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default FAQ;
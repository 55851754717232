import React, { useState, useRef, useEffect } from 'react';
import {
  Box,
  Typography,
  TextField,
  Button,
  Grid,
  Container,
  Paper,
  IconButton,
  InputAdornment,
  useTheme,
  useMediaQuery,
  Alert,
  Snackbar
} from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import SendIcon from '@mui/icons-material/Send';
import PersonIcon from '@mui/icons-material/Person';
import BusinessIcon from '@mui/icons-material/Business';
import SubjectIcon from '@mui/icons-material/Subject';
import emailjs from '@emailjs/browser';

// EmailJS configuration
const SERVICE_ID = "service_ixntbtb";
const TEMPLATE_ID = "template_sjq5s6f";
const PUBLIC_KEY = "YJyD3ZgEoRxdV8FRk";

// Define gtag_report_conversion globally or within the component
const gtag_report_conversion = (url) => {
  const callback = () => {
    if (typeof url !== 'undefined') {
      window.location = url;
    }
  };
  window.gtag('event', 'conversion', {
    'send_to': 'AW-16753652742/xAxfCM3p6bIaEIbo4bQ-',
    'event_callback': callback
  });
  return false;
};

// Intersection Observer hook (unchanged)
const useIntersectionObserver = (options) => {
  const ref = useRef(null);
  const [isVisible, setIsVisible] = React.useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setIsVisible(entry.isIntersecting);
    }, options);

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [ref, options]);

  return [ref, isVisible];
};

const Contact = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const [sectionRef, isSectionVisible] = useIntersectionObserver({
    threshold: 0.1,
  });

  useEffect(() => {
    emailjs.init(PUBLIC_KEY);
  }, []);

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    company: '',
    subject: '',
    message: '',
  });

  const [errors, setErrors] = useState({});
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success'
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    if (errors[name]) {
      setErrors({
        ...errors,
        [name]: null,
      });
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.name.trim()) newErrors.name = 'Name is required';
    if (!formData.email.trim()) {
      newErrors.email = 'Email is required';
    } else if (!/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(formData.email)) {
      newErrors.email = 'Please enter a valid email address';
    }
    if (!formData.message.trim()) newErrors.message = 'Message is required';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (validateForm()) {
      setSnackbar({
        open: true,
        message: 'Sending message...',
        severity: 'info'
      });

      const templateParams = {
        to_email: 'support@reignovation.com',
        from_name: formData.name,
        from_email: formData.email,
        from_company: formData.company || 'Not specified',
        subject: formData.subject || `Contact from ${formData.name}`,
        message: formData.message
      };

      emailjs.send(SERVICE_ID, TEMPLATE_ID, templateParams)
        .then((response) => {
          console.log('SUCCESS!', response.status, response.text);

          // Trigger Google conversion event on successful submission
          gtag_report_conversion();

          setSnackbar({
            open: true,
            message: 'Message sent successfully! We will contact you soon.',
            severity: 'success'
          });

          setFormData({
            name: '',
            email: '',
            company: '',
            subject: '',
            message: '',
          });
        })
        .catch((error) => {
          console.error('FAILED...', error);
          setSnackbar({
            open: true,
            message: 'Failed to send message. Please try again later.',
            severity: 'error'
          });
        });
    } else {
      setSnackbar({
        open: true,
        message: 'Please fill out all required fields correctly.',
        severity: 'error'
      });
    }
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') return;
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <Box
      id="contact"
      sx={{
        py: { xs: 10, md: 12 },
        background: 'linear-gradient(180deg, rgba(32, 58, 67, 0.95) 0%, rgba(15, 32, 39, 0.95) 100%)',
        position: 'relative',
        overflow: 'hidden',
      }}
      ref={sectionRef}
    >
      <Container maxWidth="lg">
        {/* Section Header */}
        <Box 
          sx={{ 
            textAlign: 'center', 
            mb: { xs: 6, md: 8 },
            opacity: isSectionVisible ? 1 : 0,
            transform: isSectionVisible ? 'translateY(0)' : 'translateY(30px)',
            transition: 'opacity 0.6s ease, transform 0.6s ease',
          }}
        >
          <Typography
            variant="overline"
            component="p"
            sx={{
              color: 'primary.main',
              fontWeight: 600,
              letterSpacing: 1.5,
              mb: 1,
              display: 'inline-block',
            }}
          >
            GET IN TOUCH
          </Typography>
          
          <Typography
            variant="h2"
            component="h2"
            sx={{
              fontWeight: 700,
              mb: 2,
              background: 'linear-gradient(90deg, #fff, #bcc5d0)',
              backgroundClip: 'text',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              textFillColor: 'transparent',
            }}
          >
            Let's Start a Conversation
          </Typography>
          
          <Typography
            variant="subtitle1"
            sx={{
              color: 'text.secondary',
              maxWidth: '800px',
              mx: 'auto',
            }}
          >
            Have questions or ready to begin your digital transformation journey?
            Reach out to our team and discover how we can help bring your vision to life.
          </Typography>
        </Box>
        
        <Grid container spacing={4}>
          {/* Contact Information Card */}
          <Grid item xs={12} md={5} lg={4}
            sx={{
              opacity: isSectionVisible ? 1 : 0,
              transform: isSectionVisible ? 'translateX(0)' : 'translateX(-30px)',
              transition: 'opacity 0.6s ease, transform 0.6s ease',
              transitionDelay: '0.2s',
            }}
          >
            <Paper
              elevation={0}
              sx={{
                p: 4,
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                background: 'linear-gradient(135deg, rgba(142, 45, 226, 0.15), rgba(74, 0, 224, 0.1))',
                backdropFilter: 'blur(10px)',
                borderRadius: 4,
                border: '1px solid rgba(142, 45, 226, 0.2)',
              }}
            >
              <Typography 
                variant="h5" 
                component="h3" 
                sx={{ 
                  fontWeight: 600, 
                  mb: 3,
                  color: 'primary.light',
                }}
              >
                Contact Information
              </Typography>
              
              <Typography 
                variant="body2" 
                sx={{ 
                  color: 'text.secondary',
                  mb: 4, 
                }}
              >
                Ready to take your business to the next level? Connect with our experts
                to discuss your project or get answers to your questions.
              </Typography>
              
              <Box sx={{ flex: 1 }}>
                <Box sx={{ display: 'flex', mb: 3, alignItems: 'center' }}>
                  <IconButton 
                    sx={{ 
                      mr: 2, 
                      bgcolor: 'rgba(142, 45, 226, 0.1)',
                      color: 'primary.main',
                      '&:hover': {
                        bgcolor: 'rgba(142, 45, 226, 0.2)',
                      },
                    }}
                  >
                    <EmailIcon />
                  </IconButton>
                  
                  <Box>
                    <Typography variant="body2" color="text.secondary">
                      Email Us
                    </Typography>
                    <Typography variant="body1" fontWeight={500}>
                      support@reignovation.com
                    </Typography>
                  </Box>
                </Box>
                
                <Box sx={{ display: 'flex', mb: 3, alignItems: 'center' }}>
                  <IconButton 
                    sx={{ 
                      mr: 2, 
                      bgcolor: 'rgba(142, 45, 226, 0.1)',
                      color: 'primary.main',
                      '&:hover': {
                        bgcolor: 'rgba(142, 45, 226, 0.2)',
                      },
                    }}
                  >
                  </IconButton>
                </Box>
              </Box>
            </Paper>
          </Grid>
          
          {/* Contact Form */}
          <Grid item xs={12} md={7} lg={8}
            sx={{
              opacity: isSectionVisible ? 1 : 0,
              transform: isSectionVisible ? 'translateX(0)' : 'translateX(30px)',
              transition: 'opacity 0.6s ease, transform 0.6s ease',
              transitionDelay: '0.3s',
            }}
          >
            <Paper
              elevation={0}
              component="form"
              onSubmit={handleSubmit}
              sx={{
                p: 4,
                background: 'rgba(26, 35, 50, 0.7)',
                backdropFilter: 'blur(10px)',
                borderRadius: 4,
                border: '1px solid rgba(255, 255, 255, 0.1)',
              }}
            >
              <Typography 
                variant="h5" 
                component="h3" 
                sx={{ 
                  fontWeight: 600, 
                  mb: 3,
                }}
              >
                Send us a Message
              </Typography>
              
              <Grid container spacing={3}>
                {/* Name Field */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    name="name"
                    label="Your Name"
                    value={formData.name}
                    onChange={handleChange}
                    error={!!errors.name}
                    helperText={errors.name}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <PersonIcon sx={{ color: 'primary.main' }} />
                        </InputAdornment>
                      ),
                    }}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: 'rgba(255, 255, 255, 0.2)',
                        },
                        '&:hover fieldset': {
                          borderColor: 'primary.main',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: 'primary.main',
                        },
                      },
                    }}
                  />
                </Grid>
                
                {/* Email Field */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    name="email"
                    label="Your Email"
                    type="email"
                    value={formData.email}
                    onChange={handleChange}
                    error={!!errors.email}
                    helperText={errors.email}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <EmailIcon sx={{ color: 'primary.main' }} />
                        </InputAdornment>
                      ),
                    }}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: 'rgba(255, 255, 255, 0.2)',
                        },
                        '&:hover fieldset': {
                          borderColor: 'primary.main',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: 'primary.main',
                        },
                      },
                    }}
                  />
                </Grid>
                
                {/* Company Field */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    name="company"
                    label="Company (Optional)"
                    value={formData.company}
                    onChange={handleChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <BusinessIcon sx={{ color: 'primary.main' }} />
                        </InputAdornment>
                      ),
                    }}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: 'rgba(255, 255, 255, 0.2)',
                        },
                        '&:hover fieldset': {
                          borderColor: 'primary.main',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: 'primary.main',
                        },
                      },
                    }}
                  />
                </Grid>
                
                {/* Subject Field */}
                <Grid item xs={12} sm={6}>
                  <TextField
                    fullWidth
                    name="subject"
                    label="Subject (Optional)"
                    value={formData.subject}
                    onChange={handleChange}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">
                          <SubjectIcon sx={{ color: 'primary.main' }} />
                        </InputAdornment>
                      ),
                    }}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: 'rgba(255, 255, 255, 0.2)',
                        },
                        '&:hover fieldset': {
                          borderColor: 'primary.main',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: 'primary.main',
                        },
                      },
                    }}
                  />
                </Grid>
                
                {/* Message Field */}
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    name="message"
                    label="Your Message"
                    multiline
                    rows={6}
                    value={formData.message}
                    onChange={handleChange}
                    error={!!errors.message}
                    helperText={errors.message}
                    sx={{
                      '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                          borderColor: 'rgba(255, 255, 255, 0.2)',
                        },
                        '&:hover fieldset': {
                          borderColor: 'primary.main',
                        },
                        '&.Mui-focused fieldset': {
                          borderColor: 'primary.main',
                        },
                      },
                    }}
                  />
                </Grid>
                
                {/* Submit Button */}
                <Grid item xs={12}>
                  <Button
                    type="submit"
                    variant="contained"
                    size="large"
                    fullWidth
                    endIcon={<SendIcon />}
                    sx={{
                      py: 1.5,
                      mt: 2,
                      background: 'linear-gradient(90deg, #8e2de2, #4a00e0)',
                      boxShadow: '0 8px 20px rgba(142, 45, 226, 0.3)',
                      '&:hover': {
                        background: 'linear-gradient(90deg, #9b4dff, #5a14ff)',
                        boxShadow: '0 8px 25px rgba(142, 45, 226, 0.5)',
                      },
                    }}
                  >
                    Send Message
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Container>
      
      {/* Success/Error Snackbar */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          variant="filled"
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Contact;
// BackgroundAnimation.js
import React, { useEffect, useRef } from 'react';
import { Box } from '@mui/material';

const BackgroundAnimation = ({ variant = 'default' }) => {
  const canvasRef = useRef(null);
  
  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const width = canvas.width = window.innerWidth;
    const height = canvas.height = window.innerHeight;
    
    // Configure based on variant
    let config = {
      particleCount: 50,
      connectionDistance: 150,
      moveSpeed: 0.5,
      lineOpacity: 0.15,
      particleColor: '#8e2de2',
      backgroundColor: 'rgba(15, 32, 39, 0.8)',
    };
    
    if (variant === 'subtle') {
      config = {
        ...config,
        particleCount: 30,
        connectionDistance: 120,
        moveSpeed: 0.3,
        lineOpacity: 0.1,
      };
    } else if (variant === 'intense') {
      config = {
        ...config,
        particleCount: 80,
        connectionDistance: 180,
        moveSpeed: 0.7,
        lineOpacity: 0.2,
      };
    }
    
    // Create gradient background
    const gradient = ctx.createRadialGradient(
      width * 0.8, height * 0.8, 0,
      width * 0.8, height * 0.8, Math.max(width, height) * 0.8
    );
    gradient.addColorStop(0, '#8e2de2'); // Purple
    gradient.addColorStop(0.4, '#4a00e0'); // Deep blue
    gradient.addColorStop(1, '#0f2027'); // Dark background
    
    // Particle class
    class Particle {
      constructor() {
        this.x = Math.random() * width;
        this.y = Math.random() * height;
        this.vx = (Math.random() - 0.5) * config.moveSpeed;
        this.vy = (Math.random() - 0.5) * config.moveSpeed;
        this.radius = Math.random() * 2 + 1;
      }
      
      update() {
        this.x += this.vx;
        this.y += this.vy;
        
        // Bounce off edges
        if (this.x < 0 || this.x > width) this.vx = -this.vx;
        if (this.y < 0 || this.y > height) this.vy = -this.vy;
      }
      
      draw() {
        ctx.beginPath();
        ctx.arc(this.x, this.y, this.radius, 0, Math.PI * 2);
        ctx.fillStyle = config.particleColor;
        ctx.fill();
      }
    }
    
    // Create particles
    const particles = [];
    for (let i = 0; i < config.particleCount; i++) {
      particles.push(new Particle());
    }
    
    // Animation loop
    const animate = () => {
      // Clear canvas with gradient
      ctx.fillStyle = gradient;
      ctx.fillRect(0, 0, width, height);
      
      // Update and draw particles
      particles.forEach(particle => {
        particle.update();
        particle.draw();
        
        // Connect particles
        particles.forEach(otherParticle => {
          const dx = particle.x - otherParticle.x;
          const dy = particle.y - otherParticle.y;
          const distance = Math.sqrt(dx * dx + dy * dy);
          
          if (distance < config.connectionDistance) {
            ctx.beginPath();
            ctx.strokeStyle = `rgba(142, 45, 226, ${config.lineOpacity * (1 - distance / config.connectionDistance)})`;
            ctx.lineWidth = 0.5;
            ctx.moveTo(particle.x, particle.y);
            ctx.lineTo(otherParticle.x, otherParticle.y);
            ctx.stroke();
          }
        });
      });
      
      animationId = requestAnimationFrame(animate);
    };
    
    let animationId = requestAnimationFrame(animate);
    
    // Handle window resize
    const handleResize = () => {
      canvas.width = window.innerWidth;
      canvas.height = window.innerHeight;
    };
    
    window.addEventListener('resize', handleResize);
    
    // Cleanup
    return () => {
      window.removeEventListener('resize', handleResize);
      cancelAnimationFrame(animationId);
    };
  }, [variant]);
  
  return (
    <Box
      sx={{
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: -1,
        overflow: 'hidden',
      }}
    >
      <canvas
        ref={canvasRef}
        style={{ display: 'block', width: '100%', height: '100%' }}
      />
    </Box>
  );
};

export default BackgroundAnimation;
// ConsultingPage.js
import React, { useRef, useEffect } from 'react';
import {
  Box,
  Typography,
  Container,
  Grid,
  Card,
  CardContent,
  Button,
  useTheme,
  useMediaQuery,
  Avatar,
  Paper,
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import AnalyticsIcon from '@mui/icons-material/Analytics';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import AssessmentIcon from '@mui/icons-material/Assessment';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import LightbulbIcon from '@mui/icons-material/Lightbulb';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import AccountTreeIcon from '@mui/icons-material/AccountTree';
import TimelineIcon from '@mui/icons-material/Timeline';
import PieChartIcon from '@mui/icons-material/PieChart';
import BarChartIcon from '@mui/icons-material/BarChart';
import BusinessIcon from '@mui/icons-material/Business';

import NavBar from '../components/NavBar';
import Footer from '../components/Footer';
import BackgroundAnimation from '../components/BackgroundAnimation';

// Intersection Observer hook for animations
const useIntersectionObserver = (options) => {
  const ref = useRef(null);
  const [isVisible, setIsVisible] = React.useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setIsVisible(entry.isIntersecting);
    }, options);

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [ref, options]);

  return [ref, isVisible];
};

// ServiceCard component
const ServiceCard = ({ service, index, isVisible }) => {
  const theme = useTheme();
  
  // Animation delay
  const delay = `${0.1 + (index * 0.1)}s`;
  
  return (
    <Grid item xs={12} md={4}>
      <Card
        elevation={0}
        sx={{
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          background: 'rgba(26, 35, 50, 0.7)',
          backdropFilter: 'blur(10px)',
          borderRadius: 3,
          border: '1px solid rgba(255, 255, 255, 0.1)',
          transition: 'transform 0.3s ease, box-shadow 0.3s ease',
          opacity: isVisible ? 1 : 0,
          transform: isVisible ? 'translateY(0)' : 'translateY(30px)',
          transitionProperty: 'opacity, transform',
          transitionDuration: '0.6s',
          transitionTimingFunction: 'cubic-bezier(0.215, 0.61, 0.355, 1)',
          transitionDelay: delay,
          '&:hover': {
            transform: 'translateY(-8px)',
            boxShadow: '0 15px 35px rgba(0, 0, 0, 0.2)',
            '& .service-icon': {
              background: service.iconGradient,
              transform: 'scale(1.1) rotate(10deg)',
            }
          },
        }}
      >
        <CardContent sx={{ p: 3, flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
          <Avatar
            className="service-icon"
            sx={{
              width: 70,
              height: 70,
              backgroundColor: 'rgba(142, 45, 226, 0.15)',
              color: service.iconColor,
              mb: 2,
              transition: 'all 0.3s ease',
            }}
          >
            {service.icon}
          </Avatar>
          
          <Typography
            variant="h5"
            component="h3"
            sx={{
              fontWeight: 600,
              mb: 2,
              color: 'white',
            }}
          >
            {service.title}
          </Typography>
          
          <Typography
            variant="body2"
            sx={{
              color: 'rgba(255, 255, 255, 0.7)',
              mb: 2,
              flexGrow: 1,
            }}
          >
            {service.description}
          </Typography>
          
          <List dense sx={{ mt: 'auto', p: 0 }}>
            {service.keyPoints.map((point, i) => (
              <ListItem key={i} sx={{ px: 0, py: 0.5 }}>
                <ListItemIcon sx={{ minWidth: 32 }}>
                  <CheckCircleIcon sx={{ color: service.iconColor, fontSize: 20 }} />
                </ListItemIcon>
                <ListItemText 
                  primary={point} 
                  primaryTypographyProps={{ 
                    variant: 'body2',
                    sx: { 
                      color: 'rgba(255, 255, 255, 0.9)' 
                    }
                  }}
                />
              </ListItem>
            ))}
          </List>
        </CardContent>
      </Card>
    </Grid>
  );
};

// Process Step component
const ProcessStep = ({ step, index, isVisible }) => {
  const theme = useTheme();
  
  // Animation delay
  const delay = `${0.2 + (index * 0.1)}s`;
  
  return (
    <Grid item xs={12} md={3}>
      <Box 
        sx={{
          textAlign: 'center',
          opacity: isVisible ? 1 : 0,
          transform: isVisible ? 'translateY(0)' : 'translateY(30px)',
          transition: 'opacity 0.6s ease, transform 0.6s ease',
          transitionDelay: delay,
        }}
      >
        <Avatar
          sx={{
            width: 80,
            height: 80,
            bgcolor: 'rgba(142, 45, 226, 0.15)',
            color: 'primary.main',
            margin: '0 auto 20px',
            '& svg': {
              fontSize: 40
            }
          }}
        >
          {step.icon}
        </Avatar>
        
        <Typography
          variant="h6"
          component="h3"
          sx={{
            fontWeight: 600,
            mb: 1,
          }}
        >
          {step.title}
        </Typography>
        
        <Typography
          variant="body2"
          sx={{
            color: 'text.secondary',
          }}
        >
          {step.description}
        </Typography>
      </Box>
    </Grid>
  );
};

// Benefit Item component
const BenefitItem = ({ benefit, index, isVisible }) => {
  const delay = `${0.3 + (index * 0.1)}s`;
  
  return (
    <Grid item xs={12} sm={6} md={4}>
      <Paper
        elevation={0}
        sx={{
          p: 3,
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          background: 'rgba(26, 35, 50, 0.7)',
          backdropFilter: 'blur(10px)',
          borderRadius: 3,
          border: '1px solid rgba(255, 255, 255, 0.1)',
          opacity: isVisible ? 1 : 0,
          transform: isVisible ? 'translateY(0)' : 'translateY(30px)',
          transition: 'opacity 0.6s ease, transform 0.6s ease',
          transitionDelay: delay,
        }}
      >
        <Box 
          sx={{ 
            display: 'flex',
            alignItems: 'flex-start', 
            mb: 2
          }}
        >
          <Avatar
            sx={{
              bgcolor: 'rgba(142, 45, 226, 0.15)',
              color: 'primary.main',
              mr: 2,
            }}
          >
            {benefit.icon}
          </Avatar>
          
          <Typography
            variant="h6"
            component="h3"
            sx={{
              fontWeight: 600,
              pt: 1,
            }}
          >
            {benefit.title}
          </Typography>
        </Box>
        
        <Typography
          variant="body2"
          sx={{
            color: 'text.secondary',
          }}
        >
          {benefit.description}
        </Typography>
      </Paper>
    </Grid>
  );
};

// Case Study component
const CaseStudy = ({ caseStudy, index, isVisible }) => {
  const delay = `${0.2 + (index * 0.1)}s`;
  
  return (
    <Grid item xs={12} md={6}>
      <Paper
        elevation={0}
        sx={{
          p: 3,
          height: '100%',
          background: 'rgba(26, 35, 50, 0.7)',
          backdropFilter: 'blur(10px)',
          borderRadius: 3,
          border: '1px solid rgba(255, 255, 255, 0.1)',
          opacity: isVisible ? 1 : 0,
          transform: isVisible ? 'translateX(0)' : index % 2 === 0 ? 'translateX(-30px)' : 'translateX(30px)',
          transition: 'opacity 0.6s ease, transform 0.6s ease',
          transitionDelay: delay,
        }}
      >
        <Typography
          variant="h6"
          component="h3"
          sx={{
            fontWeight: 600,
            mb: 2,
            color: 'primary.light',
          }}
        >
          {caseStudy.title}
        </Typography>
        
        <Typography
          variant="body2"
          sx={{
            color: 'text.secondary',
            mb: 2,
          }}
        >
          <strong>Challenge:</strong> {caseStudy.challenge}
        </Typography>
        
        <Typography
          variant="body2"
          sx={{
            color: 'text.secondary',
            mb: 2,
          }}
        >
          <strong>Solution:</strong> {caseStudy.solution}
        </Typography>
        
        <Typography
          variant="body2"
          sx={{
            color: 'text.secondary',
          }}
        >
          <strong>Results:</strong> {caseStudy.results}
        </Typography>
        
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            mt: 3,
            pt: 2,
            borderTop: '1px solid rgba(255, 255, 255, 0.1)',
          }}
        >
          <Typography
            variant="caption"
            sx={{
              color: 'primary.main',
            }}
          >
            {caseStudy.industry}
          </Typography>
          
          <Typography
            variant="caption"
            sx={{
              color: 'text.secondary',
            }}
          >
            {caseStudy.time}
          </Typography>
        </Box>
      </Paper>
    </Grid>
  );
};

// Main ConsultingPage component
const ConsultingPage = () => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  
  const [heroRef, isHeroVisible] = useIntersectionObserver({ threshold: 0.1 });
  const [servicesRef, isServicesVisible] = useIntersectionObserver({ threshold: 0.1 });
  const [processRef, isProcessVisible] = useIntersectionObserver({ threshold: 0.1 });
  const [benefitsRef, isBenefitsVisible] = useIntersectionObserver({ threshold: 0.1 });
  const [caseStudiesRef, isCaseStudiesVisible] = useIntersectionObserver({ threshold: 0.1 });
  
  // Consulting services data
  const services = [
    {
      icon: <AnalyticsIcon fontSize="large" />,
      iconColor: '#a85bff',
      iconGradient: 'linear-gradient(135deg, rgba(168, 91, 255, 0.6), rgba(142, 45, 226, 0.4))',
      title: 'Process Improvement',
      description: 'Identify inefficiencies and bottlenecks in your current processes, and implement data-driven solutions that streamline operations and boost productivity.',
      keyPoints: [
        'Workflow analysis and optimization',
        'Business process reengineering',
        'Efficiency metrics and KPIs',
        'Change management support'
      ]
    },
    {
      icon: <FindInPageIcon fontSize="large" />,
      iconColor: '#61dafb',
      iconGradient: 'linear-gradient(135deg, rgba(97, 218, 251, 0.6), rgba(0, 156, 204, 0.4))',
      title: 'Project Research',
      description: 'Comprehensive research services to evaluate new ideas and opportunities, providing the data and insights you need to make informed strategic decisions.',
      keyPoints: [
        'Feasibility studies',
        'Market analysis',
        'Competitive landscape research',
        'Risk assessment and mitigation'
      ]
    },
    {
      icon: <IntegrationInstructionsIcon fontSize="large" />,
      iconColor: '#ff9d00',
      iconGradient: 'linear-gradient(135deg, rgba(255, 157, 0, 0.6), rgba(242, 113, 33, 0.4))',
      title: 'Integration Services',
      description: 'Seamlessly connect your systems and applications to create a unified technology ecosystem that improves data flow and operational efficiency.',
      keyPoints: [
        'System integration planning',
        'API development and management',
        'Legacy system integration',
        'Data migration strategies'
      ]
    }
  ];
  
  // Consulting process steps
  const processSteps = [
    {
      icon: <AssessmentIcon />,
      title: 'Discovery & Assessment',
      description: 'We analyze your current situation, identify pain points, and define key objectives for improvement.'
    },
    {
      icon: <LightbulbIcon />,
      title: 'Solution Design',
      description: 'Our team develops tailored recommendations and strategies based on research and best practices.'
    },
    {
      icon: <AccountTreeIcon />,
      title: 'Implementation',
      description: 'We execute the planned changes with minimal disruption to your ongoing operations.'
    },
    {
      icon: <TrendingUpIcon />,
      title: 'Evaluation & Refinement',
      description: 'Continuous monitoring and fine-tuning ensure optimal results and sustainable improvement.'
    }
  ];
  
  // Benefits data
  const benefits = [
    {
      icon: <TimelineIcon />,
      title: 'Increased Efficiency',
      description: 'Streamlined processes reduce waste and operational costs while improving productivity and turnaround times.'
    },
    {
      icon: <PieChartIcon />,
      title: 'Data-Driven Decisions',
      description: 'Research-backed insights help you make confident, strategic choices about your business direction.'
    },
    {
      icon: <BarChartIcon />,
      title: 'Enhanced Growth',
      description: 'Optimized operations and innovative solutions create a solid foundation for sustainable business growth.'
    },
    {
      icon: <BusinessIcon />,
      title: 'Competitive Advantage',
      description: 'Improved processes and systems help you outperform competitors and adapt to market changes.'
    },
    {
      icon: <IntegrationInstructionsIcon />,
      title: 'Seamless Integration',
      description: 'Connected systems eliminate data silos and create a unified technology ecosystem across your organization.'
    },
    {
      icon: <LightbulbIcon />,
      title: 'Innovation Enablement',
      description: 'Efficient processes free up resources for innovation and strategic initiatives that drive your business forward.'
    }
  ];
  

  // Case studies
  const caseStudies = [
    {
      title: 'Food Truck Inventory Optimization',
      challenge: 'A food truck company was experiencing significant ingredient waste and unpredictable supply costs, with no systematic approach to inventory management.',
      solution: 'We implemented a streamlined inventory tracking system with automated calculations and established a digital process for daily inventory monitoring and supply ordering.',
      results: 'Ingredient waste decreased by 30%, monthly supply costs reduced by $1,200, and they gained valuable insights into menu item profitability.',
      industry: 'Food Service',
      time: '4-week project'
    },
    {
      title: 'Salon Appointment Management System',
      challenge: 'A hair salon was facing revenue loss due to appointment scheduling issues, including missed bookings, scheduling conflicts, and inefficient client management.',
      solution: 'We integrated a comprehensive online booking platform that synchronized with their existing channels and implemented an automated client communication system.',
      results: 'No-shows reduced by 80%, scheduling errors eliminated, and administrative time decreased by 5 hours weekly while bookings increased by 25%.',
      industry: 'Personal Services',
      time: '3-week project'
    },
    {
      title: 'Hardware Store Digital Presence',
      challenge: 'A family-owned hardware business was losing market share to competitors due to limited online visibility, making it difficult for customers to discover their services and products.',
      solution: 'We developed a professional website highlighting their product selection, specialized services, and competitive advantages, with manageable inventory features and search optimization.',
      results: 'Store traffic increased by 15% within two months, special orders grew by 40%, and they successfully expanded their customer base to previously unreached demographics.',
      industry: 'Retail',
      time: '4-week project'
    },
    {
      title: 'Bakery Production Optimization',
      challenge: 'A bakery business faced production inconsistencies, frequently selling out of popular items while discarding excess inventory of others, affecting both revenue and customer satisfaction.',
      solution: 'We developed a data-driven production planning system and implemented digital order tracking with historical trend analysis.',
      results: 'Daily waste reduced by 45%, order fulfillment accuracy improved to 98%, and monthly profit increased by $1,700 without additional staffing or equipment costs.',
      industry: 'Food Production',
      time: '8-week project'
    }
  ];
  
  return (
    <Box sx={{ backgroundColor: '#0f2027', minHeight: '100vh', color: '#FFFFFF' }}>
      {/* NavBar Component */}
      <NavBar />
      
      {/* Hero Section */}
      <Box 
        ref={heroRef}
        sx={{
          position: 'relative',
          pt: { xs: 10, md: 16 },
          pb: { xs: 10, md: 16 },
          overflow: 'hidden',
        }}
      >
        <BackgroundAnimation variant="subtle" />
        
        <Container maxWidth="lg">
          <Grid container spacing={6} alignItems="center">
            <Grid item xs={12} md={6}
              sx={{
                opacity: isHeroVisible ? 1 : 0,
                transform: isHeroVisible ? 'translateY(0)' : 'translateY(30px)',
                transition: 'opacity 0.6s ease, transform 0.6s ease',
              }}
            >
              <Typography
                variant="overline"
                component="p"
                sx={{
                  color: 'primary.main',
                  fontWeight: 600,
                  letterSpacing: 1.5,
                  mb: 1,
                }}
              >
                BUSINESS CONSULTING
              </Typography>
              
              <Typography
                variant="h1"
                component="h1"
                sx={{
                  fontWeight: 800,
                  mb: 2,
                  fontSize: { xs: '2.5rem', sm: '3rem', md: '3.5rem' },
                  lineHeight: 1.2,
                  background: 'linear-gradient(90deg, #fff, #bcc5d0)',
                  backgroundClip: 'text',
                  WebkitBackgroundClip: 'text',
                  WebkitTextFillColor: 'transparent',
                  textFillColor: 'transparent',
                }}
              >
                Transform Your Business Processes
              </Typography>
              
              <Typography
                variant="h6"
                component="p"
                sx={{
                  color: 'text.secondary',
                  mb: 4,
                  maxWidth: '600px',
                }}
              >
                Expert consulting that helps you improve efficiency, validate new ideas, and integrate systems for maximum business impact.
              </Typography>
              
            
            <Button
              variant="contained"
              size="large"
              endIcon={<ArrowForwardIcon />}
              onClick={() => navigate('/#contact')}
              sx={{
                py: 1.5,
                px: 4,
                borderRadius: '50px',
                fontSize: '1rem',
                boxShadow: '0 10px 20px rgba(142, 45, 226, 0.3)',
                '&:hover': {
                  boxShadow: '0 6px 15px rgba(142, 45, 226, 0.5)',
                },
              }}
            >
              Get Started
            </Button>
            </Grid>
            <Grid item xs={12} md={6}
              sx={{
                display: { xs: 'none', md: 'block' },
                opacity: isHeroVisible ? 1 : 0,
                transform: isHeroVisible ? 'translateX(0)' : 'translateX(30px)',
                transition: 'opacity 0.6s ease, transform 0.6s ease',
                transitionDelay: '0.2s',
              }}
            >
              <Box
                sx={{
                  position: 'relative',
                  height: '100%',
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                {/* Abstract design - connected nodes visualization */}
                <Box
                  sx={{
                    position: 'relative',
                    width: '100%',
                    height: 400,
                  }}
                >
                  {/* Central node */}
                  <Box
                    sx={{
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      transform: 'translate(-50%, -50%)',
                      width: 100,
                      height: 100,
                      borderRadius: '50%',
                      background: 'linear-gradient(135deg, #8e2de2, #4a00e0)',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      boxShadow: '0 0 30px rgba(142, 45, 226, 0.5)',
                      zIndex: 5,
                    }}
                  >
                    <AnalyticsIcon sx={{ fontSize: 50, color: 'white' }} />
                  </Box>
                  
                  {/* Surrounding nodes */}
                  {[...Array(6)].map((_, i) => {
                    const angle = (i * 60) * (Math.PI / 180);
                    const radius = 150;
                    const x = Math.cos(angle) * radius;
                    const y = Math.sin(angle) * radius;
                    
                    // Define icons for each node
                    const icons = [
                      <TimelineIcon sx={{ fontSize: 30, color: 'white' }} />,
                      <FindInPageIcon sx={{ fontSize: 30, color: 'white' }} />,
                      <AccountTreeIcon sx={{ fontSize: 30, color: 'white' }} />,
                      <PieChartIcon sx={{ fontSize: 30, color: 'white' }} />,
                      <IntegrationInstructionsIcon sx={{ fontSize: 30, color: 'white' }} />,
                      <AssessmentIcon sx={{ fontSize: 30, color: 'white' }} />
                    ];
                    
                    // Define node colors
                    const colors = [
                      'linear-gradient(135deg, #a85bff, #8e2de2)',
                      'linear-gradient(135deg, #61dafb, #0099cc)',
                      'linear-gradient(135deg, #ff9d00, #ff6b00)',
                      'linear-gradient(135deg, #4cd964, #2ca645)',
                      'linear-gradient(135deg, #5ac8fa, #0090ff)',
                      'linear-gradient(135deg, #ff2d55, #e00034)'
                    ];
                    
                    return (
                      <React.Fragment key={i}>
                        {/* Connection line */}
                        <Box
                          sx={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            width: radius,
                            height: 2,
                            background: 'rgba(142, 45, 226, 0.3)',
                            transformOrigin: '0 0',
                            transform: `rotate(${angle * (180 / Math.PI)}deg)`,
                            zIndex: 1,
                            '&::after': {
                              content: '""',
                              position: 'absolute',
                              top: 0,
                              left: 0,
                              width: '100%',
                              height: '100%',
                              background: 'linear-gradient(90deg, rgba(142, 45, 226, 0.7), rgba(142, 45, 226, 0.1))',
                              animation: `pulse${i} 3s infinite ease-in-out`,
                              [`@keyframes pulse${i}`]: {
                                '0%, 100%': { opacity: 0.3 },
                                '50%': { opacity: 0.8 }
                              }
                            }
                          }}
                        />
                        
                        {/* Node */}
                        <Box
                          sx={{
                            position: 'absolute',
                            top: 'calc(50% + ' + y + 'px)',
                            left: 'calc(50% + ' + x + 'px)',
                            transform: 'translate(-50%, -50%)',
                            width: 60,
                            height: 60,
                            borderRadius: '50%',
                            background: colors[i],
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            boxShadow: '0 5px 15px rgba(0, 0, 0, 0.2)',
                            zIndex: 2,
                            animation: `float${i} ${4 + i * 0.5}s infinite ease-in-out alternate`,
                            [`@keyframes float${i}`]: {
                              '0%': { transform: 'translate(-50%, -50%) scale(1)' },
                              '100%': { transform: 'translate(-50%, -50%) scale(1.1)' }
                            }
                          }}
                        >
                          {icons[i]}
                        </Box>
                      </React.Fragment>
                    );
                  })}
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
      
      {/* Services Section */}
      <Box
        ref={servicesRef}
        sx={{
          py: { xs: 10, md: 12 },
          background: 'rgba(15, 32, 39, 0.8)',
          position: 'relative',
        }}
      >
        <Container maxWidth="lg">
          <Box
            sx={{
              textAlign: 'center',
              mb: { xs: 6, md: 8 },
              opacity: isServicesVisible ? 1 : 0,
              transform: isServicesVisible ? 'translateY(0)' : 'translateY(30px)',
              transition: 'opacity 0.6s ease, transform 0.6s ease',
            }}
          >
            <Typography
              variant="overline"
              component="p"
              sx={{
                color: 'primary.main',
                fontWeight: 600,
                letterSpacing: 1.5,
                mb: 1,
              }}
            >
              OUR EXPERTISE
            </Typography>
            
            <Typography
              variant="h2"
              component="h2"
              sx={{
                fontWeight: 700,
                mb: 2,
                background: 'linear-gradient(90deg, #fff, #bcc5d0)',
                backgroundClip: 'text',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                textFillColor: 'transparent',
              }}
            >
              Consulting Services
            </Typography>
            
            <Typography
              variant="subtitle1"
              sx={{
                color: 'text.secondary',
                maxWidth: '800px',
                mx: 'auto',
              }}
            >
              Our specialized consulting services help identify opportunities for 
              improvement and implement strategies that drive measurable results.
            </Typography>
          </Box>
          
          <Grid container spacing={4}>
            {services.map((service, index) => (
              <ServiceCard
                key={service.title}
                service={service}
                index={index}
                isVisible={isServicesVisible}
              />
            ))}
          </Grid>
        </Container>
      </Box>
      
      {/* Process Section */}
      <Box
        ref={processRef}
        sx={{
          py: { xs: 10, md: 12 },
          background: 'linear-gradient(180deg, rgba(32, 58, 67, 0.8) 0%, rgba(15, 32, 39, 0.8) 100%)',
          position: 'relative',
        }}
      >
        <Container maxWidth="lg">
          <Box
            sx={{
              textAlign: 'center',
              mb: { xs: 6, md: 8 },
              opacity: isProcessVisible ? 1 : 0,
              transform: isProcessVisible ? 'translateY(0)' : 'translateY(30px)',
              transition: 'opacity 0.6s ease, transform 0.6s ease',
            }}
          >
            <Typography
              variant="overline"
              component="p"
              sx={{
                color: 'primary.main',
                fontWeight: 600,
                letterSpacing: 1.5,
                mb: 1,
              }}
            >
              HOW WE WORK
            </Typography>
            
            <Typography
              variant="h2"
              component="h2"
              sx={{
                fontWeight: 700,
                mb: 2,
                background: 'linear-gradient(90deg, #fff, #bcc5d0)',
                backgroundClip: 'text',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                textFillColor: 'transparent',
              }}
            >
              Our Consulting Process
            </Typography>
            
            <Typography
              variant="subtitle1"
              sx={{
                color: 'text.secondary',
                maxWidth: '800px',
                mx: 'auto',
              }}
            >
              A systematic approach that ensures we thoroughly understand your challenges
              and deliver solutions that create lasting value for your business.
            </Typography>
          </Box>
          
          <Grid container spacing={4} sx={{ mb: 8 }}>
            {processSteps.map((step, index) => (
              <ProcessStep
                key={step.title}
                step={step}
                index={index}
                isVisible={isProcessVisible}
              />
            ))}
          </Grid>
          
          {/* Process details */}
          <Grid container spacing={4}
            sx={{
              opacity: isProcessVisible ? 1 : 0,
              transform: isProcessVisible ? 'translateY(0)' : 'translateY(30px)',
              transition: 'opacity 0.6s ease, transform 0.6s ease',
              transitionDelay: '0.4s',
            }}
          >
            <Grid item xs={12}>
              <Paper
                elevation={0}
                sx={{
                  p: 4,
                  background: 'rgba(26, 35, 50, 0.7)',
                  backdropFilter: 'blur(10px)',
                  borderRadius: 3,
                  border: '1px solid rgba(255, 255, 255, 0.1)',
                }}
              >
                <Grid container spacing={4}>
                  <Grid item xs={12} md={7}>
                    <Typography
                      variant="h5"
                      component="h3"
                      sx={{
                        fontWeight: 600,
                        mb: 3,
                        color: 'primary.light',
                      }}
                    >
                      Our Approach to Consulting
                    </Typography>
                    
                    <Typography
                      variant="body2"
                      sx={{
                        color: 'text.secondary',
                        mb: 3,
                        lineHeight: 1.7,
                      }}
                    >
                      At Reignovation, we believe that effective consulting begins with deep understanding. 
                      We take the time to learn about your business, your industry, and your specific challenges 
                      before recommending any solutions. This thorough approach allows us to identify the 
                      root causes of issues rather than just addressing symptoms.
                    </Typography>
                    
                    <Typography
                      variant="body2"
                      sx={{
                        color: 'text.secondary',
                        mb: 3,
                        lineHeight: 1.7,
                      }}
                    >
                      Our consultants bring expertise from a wide range of industries and specialties, 
                      allowing us to provide fresh perspectives and innovative solutions. We combine this 
                      knowledge with data-driven analysis to ensure that our recommendations are based on 
                      solid evidence rather than assumptions.
                    </Typography>
                    
                    <Typography
                      variant="body2"
                      sx={{
                        color: 'text.secondary',
                        lineHeight: 1.7,
                      }}
                    >
                      Most importantly, we work collaboratively with your team throughout the entire process. 
                      We believe that the best solutions are developed together, drawing on both our expertise 
                      and your deep understanding of your business. This partnership approach ensures that 
                      the improvements we implement are sustainable and continue delivering value long after 
                      our engagement ends.
                    </Typography>
                  </Grid>
                  
                  <Grid item xs={12} md={5}>
                    <Box
                      sx={{
                        height: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                      }}
                    >
                      <Box
                        sx={{
                          mb: 3,
                          p: 3,
                          background: 'rgba(142, 45, 226, 0.1)',
                          borderRadius: 2,
                          border: '1px solid rgba(142, 45, 226, 0.2)',
                        }}
                      >
                        <Typography
                          variant="subtitle1"
                          sx={{
                            fontWeight: 600,
                            mb: 1,
                            color: 'primary.light',
                          }}
                        >
                          Industry Expertise
                        </Typography>
                        
                        <Typography
                          variant="body2"
                          sx={{
                            color: 'text.secondary',
                          }}
                        >
                          Our team brings experience from multiple sectors including manufacturing, 
                          healthcare, financial services, retail, and technology.
                        </Typography>
                      </Box>
                      
                      <Box
                        sx={{
                          mb: 3,
                          p: 3,
                          background: 'rgba(142, 45, 226, 0.1)',
                          borderRadius: 2,
                          border: '1px solid rgba(142, 45, 226, 0.2)',
                        }}
                      >
                        <Typography
                          variant="subtitle1"
                          sx={{
                            fontWeight: 600,
                            mb: 1,
                            color: 'primary.light',
                          }}
                        >
                          Data-Driven Methods
                        </Typography>
                        
                        <Typography
                          variant="body2"
                          sx={{
                            color: 'text.secondary',
                          }}
                        >
                          We use advanced analytics and research methodologies to ensure our 
                          recommendations are based on solid evidence and measurable outcomes.
                        </Typography>
                      </Box>
                      
                      <Box
                        sx={{
                          p: 3,
                          background: 'rgba(142, 45, 226, 0.1)',
                          borderRadius: 2,
                          border: '1px solid rgba(142, 45, 226, 0.2)',
                        }}
                      >
                        <Typography
                          variant="subtitle1"
                          sx={{
                            fontWeight: 600,
                            mb: 1,
                            color: 'primary.light',
                          }}
                        >
                          Collaborative Partnership
                        </Typography>
                        
                        <Typography
                          variant="body2"
                          sx={{
                            color: 'text.secondary',
                          }}
                        >
                          We work as an extension of your team, ensuring knowledge transfer 
                          and building internal capabilities for long-term success.
                        </Typography>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </Container>
      </Box>
      
      {/* Benefits Section */}
      <Box
        ref={benefitsRef}
        sx={{
          py: { xs: 10, md: 12 },
          background: 'rgba(15, 32, 39, 0.8)',
          position: 'relative',
        }}
      >
        <Container maxWidth="lg">
          <Box
            sx={{
              textAlign: 'center',
              mb: { xs: 6, md: 8 },
              opacity: isBenefitsVisible ? 1 : 0,
              transform: isBenefitsVisible ? 'translateY(0)' : 'translateY(30px)',
              transition: 'opacity 0.6s ease, transform 0.6s ease',
            }}
          >
            <Typography
              variant="overline"
              component="p"
              sx={{
                color: 'primary.main',
                fontWeight: 600,
                letterSpacing: 1.5,
                mb: 1,
              }}
            >
              WHY CHOOSE US
            </Typography>
            
            <Typography
              variant="h2"
              component="h2"
              sx={{
                fontWeight: 700,
                mb: 2,
                background: 'linear-gradient(90deg, #fff, #bcc5d0)',
                backgroundClip: 'text',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                textFillColor: 'transparent',
              }}
            >
              Benefits of Our Consulting Services
            </Typography>
            
            <Typography
              variant="subtitle1"
              sx={{
                color: 'text.secondary',
                maxWidth: '800px',
                mx: 'auto',
              }}
            >
              Our consulting services deliver tangible outcomes that positively 
              impact your business performance and competitive position.
            </Typography>
          </Box>
          
          <Grid container spacing={4}>
            {benefits.map((benefit, index) => (
              <BenefitItem
                key={benefit.title}
                benefit={benefit}
                index={index}
                isVisible={isBenefitsVisible}
              />
            ))}
          </Grid>
        </Container>
      </Box>
      
      {/* Case Studies Section */}
      <Box
        ref={caseStudiesRef}
        sx={{
          py: { xs: 10, md: 12 },
          background: 'linear-gradient(180deg, rgba(32, 58, 67, 0.8) 0%, rgba(15, 32, 39, 0.8) 100%)',
          position: 'relative',
        }}
      >
        <Container maxWidth="lg">
          <Box
            sx={{
              textAlign: 'center',
              mb: { xs: 6, md: 8 },
              opacity: isCaseStudiesVisible ? 1 : 0,
              transform: isCaseStudiesVisible ? 'translateY(0)' : 'translateY(30px)',
              transition: 'opacity 0.6s ease, transform 0.6s ease',
            }}
          >
            <Typography
              variant="overline"
              component="p"
              sx={{
                color: 'primary.main',
                fontWeight: 600,
                letterSpacing: 1.5,
                mb: 1,
              }}
            >
              SUCCESS STORIES
            </Typography>
            
            <Typography
              variant="h2"
              component="h2"
              sx={{
                fontWeight: 700,
                mb: 2,
                background: 'linear-gradient(90deg, #fff, #bcc5d0)',
                backgroundClip: 'text',
                WebkitBackgroundClip: 'text',
                WebkitTextFillColor: 'transparent',
                textFillColor: 'transparent',
              }}
            >
              Case Studies
            </Typography>
            
            <Typography
              variant="subtitle1"
              sx={{
                color: 'text.secondary',
                maxWidth: '800px',
                mx: 'auto',
              }}
            >
              Real-world examples of how our consulting services have helped 
              organizations overcome challenges and achieve their goals.
            </Typography>
          </Box>
          
          <Grid container spacing={4}>
            {caseStudies.map((caseStudy, index) => (
              <CaseStudy
                key={caseStudy.title}
                caseStudy={caseStudy}
                index={index}
                isVisible={isCaseStudiesVisible}
              />
            ))}
          </Grid>
        </Container>
      </Box>
      
      {/* CTA Section */}
      <Box
        sx={{
          py: { xs: 8, md: 10 },
          position: 'relative',
          background: 'linear-gradient(135deg, rgba(142, 45, 226, 0.2), rgba(74, 0, 224, 0.2))',
          backdropFilter: 'blur(10px)',
        }}
      >
        <Container maxWidth="md">
          <Box
            sx={{
              textAlign: 'center',
              background: 'rgba(26, 35, 50, 0.7)',
              backdropFilter: 'blur(20px)',
              borderRadius: 3,
              border: '1px solid rgba(255, 255, 255, 0.1)',
              p: { xs: 4, md: 6 },
            }}
          >
            <Typography
              variant="h3"
              component="h2"
              sx={{
                fontWeight: 700,
                mb: 2,
              }}
            >
              Ready to Transform Your Business?
            </Typography>
            
            <Typography
              variant="subtitle1"
              sx={{
                color: 'text.secondary',
                mb: 4,
                maxWidth: '800px',
                mx: 'auto',
              }}
            >
              Contact us today to discuss how our consulting services can help you 
              optimize processes, validate ideas, and drive sustainable growth.
            </Typography>
            
            <Button
              variant="contained"
              size="large"
              onClick={() => navigate('/#contact')}
              sx={{
                py: 1.5,
                px: 4,
                borderRadius: '50px',
                fontSize: '1.1rem',
                boxShadow: '0 10px 20px rgba(142, 45, 226, 0.3)',
                '&:hover': {
                  boxShadow: '0 6px 15px rgba(142, 45, 226, 0.5)',
                },
              }}
            >
              Schedule a Consultation
            </Button>
          </Box>
        </Container>
      </Box>
      
      {/* Footer */}
      <Footer />
    </Box>
  );
};

export default ConsultingPage;
// Highlights.js
import React, { useRef, useEffect } from 'react';
import {
  Box,
  Typography,
  Container,
  Grid,
  Card,
  CardContent,
  useTheme,
  useMediaQuery,
  Avatar
} from '@mui/material';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';
import SecurityIcon from '@mui/icons-material/Security';
import SpeedIcon from '@mui/icons-material/Speed';
import DoneIcon from '@mui/icons-material/Done';
import IntegrationInstructionsIcon from '@mui/icons-material/IntegrationInstructions';
import SupportIcon from '@mui/icons-material/Support';

// Intersection Observer hook for animations
const useIntersectionObserver = (options) => {
  const ref = useRef(null);
  const [isVisible, setIsVisible] = React.useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setIsVisible(entry.isIntersecting);
    }, options);

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [ref, options]);

  return [ref, isVisible];
};

const Highlights = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isTablet = useMediaQuery(theme.breakpoints.down('lg'));
  
  const [sectionRef, isSectionVisible] = useIntersectionObserver({
    threshold: 0.1,
  });
  
  // Highlight items data
  const highlights = [
    {
      icon: <RocketLaunchIcon fontSize="large" />,
      title: 'Rapid Deployment',
      description: 'Get up and running quickly with solutions tailored to your immediate needs.',
      color: '#a85bff',
    },
    {
      icon: <SecurityIcon fontSize="large" />,
      title: 'Reliable Security',
      description: 'Protect your valuable data with appropriate security measures without enterprise costs.',
      color: '#61dafb',
    },
    {
      icon: <SpeedIcon fontSize="large" />,
      title: 'Efficient Performance',
      description: 'Streamlined solutions designed to maximize output without unnecessary complexity.',
      color: '#ff9d00',
    },
    {
      icon: <DoneIcon fontSize="large" />,
      title: 'Practical Solutions',
      description: 'Focused services that address your specific pain points without bloated features.',
      color: '#4cd964',
    },
    {
      icon: <IntegrationInstructionsIcon fontSize="large" />,
      title: 'Workflow Enhancement',
      description: 'Improve your day-to-day operations to save time and reduce operational costs.',
      color: '#5ac8fa',
    },
    {
      icon: <SupportIcon fontSize="large" />,
      title: 'Responsive Support',
      description: 'Get help when you need it from a team that knows your business and your system.',
      color: '#ff2d55',
    },
  ];

  // Animation settings
  const containerAnimation = {
    opacity: isSectionVisible ? 1 : 0,
    transform: isSectionVisible ? 'translateY(0)' : 'translateY(30px)',
    transition: 'opacity 0.6s ease, transform 0.6s ease',
  };
  
  // Highlight card component
  const HighlightCard = ({ highlight, index }) => {
    // Staggered animation delay
    const delay = `${0.2 + (index * 0.1)}s`;
    
    return (
      <Grid item xs={12} sm={6} md={4}>
        <Card
          elevation={0}
          sx={{
            height: '100%',
            background: 'rgba(26, 35, 50, 0.5)',
            backdropFilter: 'blur(10px)',
            borderRadius: 3,
            border: '1px solid rgba(255, 255, 255, 0.1)',
            transition: 'transform 0.3s ease, box-shadow 0.3s ease',
            overflow: 'visible',
            position: 'relative',
            opacity: isSectionVisible ? 1 : 0,
            transform: isSectionVisible ? 'translateY(0)' : 'translateY(30px)',
            transitionProperty: 'opacity, transform',
            transitionDuration: '0.6s',
            transitionTimingFunction: 'cubic-bezier(0.215, 0.61, 0.355, 1)',
            transitionDelay: delay,
            '&:hover': {
              transform: 'translateY(-10px)',
              boxShadow: '0 10px 25px rgba(0, 0, 0, 0.2)',
              '& .highlight-icon': {
                transform: 'scale(1.1) rotate(10deg)',
                boxShadow: `0 10px 25px rgba(${hexToRgb(highlight.color)}, 0.4)`,
              },
            },
          }}
        >
          <CardContent sx={{ p: 3, textAlign: 'center' }}>
            <Avatar
              className="highlight-icon"
              sx={{
                width: 70,
                height: 70,
                backgroundColor: highlight.color,
                color: 'white',
                margin: '0 auto 20px',
                transition: 'all 0.3s ease',
                boxShadow: `0 5px 15px rgba(${hexToRgb(highlight.color)}, 0.3)`,
              }}
            >
              {highlight.icon}
            </Avatar>
            
            <Typography
              variant="h6"
              component="h3"
              sx={{
                fontWeight: 600,
                mb: 1,
                color: 'white',
              }}
            >
              {highlight.title}
            </Typography>
            
            <Typography
              variant="body2"
              sx={{
                color: 'rgba(255, 255, 255, 0.7)',
              }}
            >
              {highlight.description}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    );
  };
  
  // Helper function to convert hex color to rgb
  function hexToRgb(hex) {
    // Remove the hash if it exists
    hex = hex.replace('#', '');
    
    // Parse the hex values
    const r = parseInt(hex.substring(0, 2), 16);
    const g = parseInt(hex.substring(2, 4), 16);
    const b = parseInt(hex.substring(4, 6), 16);
    
    // Return the RGB values as a string
    return `${r}, ${g}, ${b}`;
  }
  
  return (
    <Box
      id="highlights"
      sx={{
        py: { xs: 8, md: 12 },
        background: 'linear-gradient(135deg, rgba(15, 32, 39, 0.95) 0%, rgba(32, 58, 67, 0.95) 100%)',
        position: 'relative',
        overflow: 'hidden',
      }}
      ref={sectionRef}
    >
      {/* Animated background gradient */}
      <Box
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          background: 'radial-gradient(circle at 20% 30%, rgba(142, 45, 226, 0.15), transparent 40%), radial-gradient(circle at 80% 70%, rgba(74, 0, 224, 0.1), transparent 40%)',
          animation: 'pulseGradient 10s infinite alternate ease-in-out',
          '@keyframes pulseGradient': {
            '0%': {
              opacity: 0.5,
            },
            '100%': {
              opacity: 0.8,
            },
          },
        }}
      />
      
      <Container maxWidth="lg">
        {/* Section Header */}
        <Box 
          sx={{ 
            textAlign: 'center', 
            mb: { xs: 6, md: 8 },
            ...containerAnimation,
          }}
        >
          <Typography
            variant="overline"
            component="p"
            sx={{
              color: 'primary.main',
              fontWeight: 600,
              letterSpacing: 1.5,
              mb: 1,
              display: 'inline-block',
            }}
          >
            WHY CHOOSE US
          </Typography>
          
          <Typography
            variant="h2"
            component="h2"
            sx={{
              fontWeight: 700,
              mb: 2,
              background: 'linear-gradient(90deg, #fff, #bcc5d0)',
              backgroundClip: 'text',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              textFillColor: 'transparent',
            }}
          >
            Our Core Strengths
          </Typography>
          
          <Typography
            variant="subtitle1"
            sx={{
              color: 'text.secondary',
              maxWidth: '800px',
              mx: 'auto',
            }}
          >
            Discover what sets our solutions apart and how we deliver exceptional results for every client.
          </Typography>
        </Box>
        
        {/* Highlights Grid */}
        <Grid container spacing={4}>
          {highlights.map((highlight, index) => (
            <HighlightCard 
              key={highlight.title} 
              highlight={highlight} 
              index={index} 
            />
          ))}
        </Grid>
        
        {/* Stats Section - FIXED TO ENSURE TEXT FITS WITHIN CIRCLES */}
        <Box sx={{ mt: 10, ...containerAnimation, transitionDelay: '0.6s' }}>
          <Grid 
            container 
            spacing={4} 
            justifyContent="center"
            sx={{
              textAlign: 'center',
              position: 'relative',
              // Line connecting circles has been removed
            }}
          >
            {/* Stat Item 1 */}
            <Grid item xs={6} md={3}
              sx={{
                position: 'relative',
                zIndex: 1,
              }}
            >
              <Box
                sx={{
                  position: 'relative',
                  width: { xs: '120px', sm: '140px', md: '160px' },
                  height: { xs: '120px', sm: '140px', md: '160px' },
                  margin: '0 auto',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  '&::before': {
                    content: '""',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '100%',
                    height: '100%',
                    borderRadius: '50%',
                    background: 'rgba(142, 45, 226, 0.05)',
                    zIndex: -1,
                  },
                }}
              >
                <Typography
                  variant="h3"
                  sx={{
                    fontWeight: 700,
                    fontSize: { xs: '1.75rem', sm: '2.25rem', md: '2.5rem' },
                    lineHeight: 1,
                    my: 0.5,
                    background: 'linear-gradient(90deg, #8e2de2, #4a00e0)',
                    backgroundClip: 'text',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                    textFillColor: 'transparent',
                  }}
                >
                  100%
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    color: 'text.secondary',
                    fontWeight: 500,
                    fontSize: { xs: '0.75rem', sm: '0.825rem', md: '0.875rem' },
                    mt: 0.5,
                  }}
                >
                  Client Commitment
                </Typography>
              </Box>
            </Grid>
            
            {/* Stat Item 2 */}
            <Grid item xs={6} md={3}
              sx={{
                position: 'relative',
                zIndex: 1,
              }}
            >
              <Box
                sx={{
                  position: 'relative',
                  width: { xs: '120px', sm: '140px', md: '160px' },
                  height: { xs: '120px', sm: '140px', md: '160px' },
                  margin: '0 auto',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  '&::before': {
                    content: '""',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '100%',
                    height: '100%',
                    borderRadius: '50%',
                    background: 'rgba(142, 45, 226, 0.05)',
                    zIndex: -1,
                  },
                }}
              >
                <Typography
                  variant="h3"
                  sx={{
                    fontWeight: 700,
                    fontSize: { xs: '1.5rem', sm: '2rem', md: '2.25rem' },
                    lineHeight: 1,
                    my: 0.5,
                    background: 'linear-gradient(90deg, #8e2de2, #4a00e0)',
                    backgroundClip: 'text',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                    textFillColor: 'transparent',
                  }}
                >
                  Custom
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    color: 'text.secondary',
                    fontWeight: 500,
                    fontSize: { xs: '0.75rem', sm: '0.825rem', md: '0.875rem' },
                    mt: 0.5,
                  }}
                >
                  Tailored Solutions
                </Typography>
              </Box>
            </Grid>
            
            {/* Stat Item 3 */}
            <Grid item xs={6} md={3}
              sx={{
                position: 'relative',
                zIndex: 1,
              }}
            >
              <Box
                sx={{
                  position: 'relative',
                  width: { xs: '120px', sm: '140px', md: '160px' },
                  height: { xs: '120px', sm: '140px', md: '160px' },
                  margin: '0 auto',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  '&::before': {
                    content: '""',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '100%',
                    height: '100%',
                    borderRadius: '50%',
                    background: 'rgba(142, 45, 226, 0.05)',
                    zIndex: -1,
                  },
                }}
              >
                <Typography
                  variant="h3"
                  sx={{
                    fontWeight: 700,
                    fontSize: { xs: '1.75rem', sm: '2.25rem', md: '2.5rem' },
                    lineHeight: 1,
                    my: 0.5,
                    background: 'linear-gradient(90deg, #8e2de2, #4a00e0)',
                    backgroundClip: 'text',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                    textFillColor: 'transparent',
                  }}
                >
                  10+
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    color: 'text.secondary',
                    fontWeight: 500,
                    fontSize: { xs: '0.75rem', sm: '0.825rem', md: '0.875rem' },
                    mt: 0.5,
                  }}
                >
                  Years Experience
                </Typography>
              </Box>
            </Grid>
            
            {/* Stat Item 4 */}
            <Grid item xs={6} md={3}
              sx={{
                position: 'relative',
                zIndex: 1,
              }}
            >
              <Box
                sx={{
                  position: 'relative',
                  width: { xs: '120px', sm: '140px', md: '160px' },
                  height: { xs: '120px', sm: '140px', md: '160px' },
                  margin: '0 auto',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  '&::before': {
                    content: '""',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: '100%',
                    height: '100%',
                    borderRadius: '50%',
                    background: 'rgba(142, 45, 226, 0.05)',
                    zIndex: -1,
                  },
                }}
              >
                <Typography
                  variant="h3"
                  sx={{
                    fontWeight: 700,
                    fontSize: { xs: '1.75rem', sm: '2.25rem', md: '2.5rem' },
                    lineHeight: 1,
                    my: 0.5,
                    background: 'linear-gradient(90deg, #8e2de2, #4a00e0)',
                    backgroundClip: 'text',
                    WebkitBackgroundClip: 'text',
                    WebkitTextFillColor: 'transparent',
                    textFillColor: 'transparent',
                  }}
                >
                  24/7
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    color: 'text.secondary',
                    fontWeight: 500,
                    fontSize: { xs: '0.75rem', sm: '0.825rem', md: '0.875rem' },
                    mt: 0.5,
                  }}
                >
                  Technical Support
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default Highlights;
// Hero.js
import React, { useEffect, useState, useRef } from 'react';
import { 
  Box, 
  Typography, 
  Button, 
  Container, 
  Grid,
  useMediaQuery
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

// Final version of word scrambler with proper cycling and cleanup
const WordScrambler = ({ words, displayDuration = 2000, transitionDuration = 1000 }) => {
  const [displayedWord, setDisplayedWord] = useState('');
  const wordIndexRef = useRef(0);
  const timeoutRef = useRef(null);
  const intervalRef = useRef(null);
  
  // Effect for initializing and running the animation cycle
  useEffect(() => {
    // Validate words array
    if (!words || !Array.isArray(words) || words.length === 0) {
      console.error('WordScrambler: Invalid or empty words array');
      return;
    }
    
    // Set initial word
    setDisplayedWord(words[0]);
    
    // Function to handle the transition to the next word
    const cycleToNextWord = () => {
      // Get current and next indexes
      const currentIndex = wordIndexRef.current;
      const nextIndex = (currentIndex + 1) % words.length;
      
      // Get the current and next words
      const currentWord = words[currentIndex];
      const nextWord = words[nextIndex];
      
      // Setup the scramble animation
      let step = 0;
      const totalSteps = 10;
      
      // Clear any existing intervals just to be safe
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
      
      // Start the scramble animation
      intervalRef.current = setInterval(() => {
        step++;
        
        if (step <= totalSteps) {
          // Calculate progress (0 to 1)
          const progress = step / totalSteps;
          
          // Generate scrambled text that transitions between words
          const scrambledText = scrambleText(currentWord, nextWord, progress);
          setDisplayedWord(scrambledText);
          
          // When we reach the final step
          if (step === totalSteps) {
            // Clear the interval
            clearInterval(intervalRef.current);
            
            // Set the clean next word
            setDisplayedWord(nextWord);
            
            // Update the index
            wordIndexRef.current = nextIndex;
            
            // Schedule the next word change
            timeoutRef.current = setTimeout(cycleToNextWord, displayDuration);
          }
        }
      }, transitionDuration / totalSteps);
    };
    
    // Function to generate scrambled text that transitions between words
    const scrambleText = (fromText, toText, progress) => {
      const chars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';
      let result = '';
      
      // Get the maximum length between the two words
      const maxLength = Math.max(fromText.length, toText.length);
      
      for (let i = 0; i < maxLength; i++) {
        // In the first half of the animation, we scramble the current word
        if (progress < 0.5) {
          if (i < fromText.length) {
            // Scramble characters with higher probability in the middle of the animation
            if (Math.random() > progress * 2) {
              result += chars.charAt(Math.floor(Math.random() * chars.length));
            } else {
              result += fromText.charAt(i);
            }
          }
        } 
        // In the second half, we start forming the next word
        else {
          if (i < toText.length) {
            // Form the new word with increasing probability as we get closer to the end
            if (Math.random() > (progress - 0.5) * 2) {
              result += chars.charAt(Math.floor(Math.random() * chars.length));
            } else {
              result += toText.charAt(i);
            }
          }
        }
      }
      
      return result;
    };
    
    // Start the first cycle after initial display duration
    timeoutRef.current = setTimeout(cycleToNextWord, displayDuration);
    
    // Cleanup function to prevent memory leaks
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      if (intervalRef.current) {
        clearInterval(intervalRef.current);
      }
    };
  }, [words, displayDuration, transitionDuration]);
  
  return (
    <Typography
      component="span"
      sx={{
        fontFamily: "'Roboto Mono', monospace",
        color: 'primary.main',
        textShadow: '0 0 10px rgba(142, 45, 226, 0.5)',
        fontSize: 'inherit',
        fontWeight: 'inherit',
        display: 'inline',
      }}
    >
      {displayedWord}
    </Typography>
  );
};

const Hero = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const isTablet = useMediaQuery(theme.breakpoints.down('lg'));
  
  const words = [
    "Create",
    "Innovate",
    "Elevate",
    "Reinvent",
    "Develop",
    "Optimize",
  ];

  const handleScrollDown = () => {
    const featuresSection = document.getElementById('features');
    if (featuresSection) {
      featuresSection.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <Box
      id="hero"
      sx={{
        position: 'relative',
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        overflow: 'hidden',
        pt: { xs: 4, md: 0 },
        background: 'linear-gradient(135deg, rgba(15, 32, 39, 0.95) 0%, rgba(32, 58, 67, 0.95) 100%)',
      }}
    >
      {/* Background particles effect */}
      <Box 
        sx={{
          position: 'absolute',
          top: 0,
          left: 0,
          right: 0,
          bottom: 0,
          zIndex: 0,
          pointerEvents: 'none',
          overflow: 'hidden',
        }}
      >
        {/* Animated background gradient */}
        <Box
          sx={{
            position: 'absolute',
            top: 0,
            right: 0,
            width: '60%',
            height: '60%',
            background: 'radial-gradient(circle at center, rgba(142, 45, 226, 0.4) 0%, rgba(142, 45, 226, 0) 70%)',
            opacity: 0.6,
            animation: 'pulse 15s infinite ease-in-out',
            '@keyframes pulse': {
              '0%, 100%': { opacity: 0.6, transform: 'scale(1) translate(0, 0)' },
              '50%': { opacity: 0.8, transform: 'scale(1.1) translate(-5%, 5%)' },
            },
          }}
        />
        
        {/* Particles effect */}
        {Array.from({ length: 20 }).map((_, index) => (
          <Box
            key={index}
            sx={{
              position: 'absolute',
              width: Math.random() * 6 + 2, // 2-8px
              height: Math.random() * 6 + 2, // 2-8px
              backgroundColor: 'rgba(142, 45, 226, 0.4)',
              borderRadius: '50%',
              top: `${Math.random() * 100}%`,
              left: `${Math.random() * 100}%`,
              animation: `float${index % 5 + 1} ${Math.random() * 10 + 10}s infinite ease-in-out`,
              '@keyframes float1': {
                '0%, 100%': { transform: 'translate(0, 0)' },
                '50%': { transform: 'translate(100px, 100px)' },
              },
              '@keyframes float2': {
                '0%, 100%': { transform: 'translate(0, 0)' },
                '50%': { transform: 'translate(-100px, 50px)' },
              },
              '@keyframes float3': {
                '0%, 100%': { transform: 'translate(0, 0)' },
                '50%': { transform: 'translate(50px, -70px)' },
              },
              '@keyframes float4': {
                '0%, 100%': { transform: 'translate(0, 0)' },
                '50%': { transform: 'translate(-50px, -100px)' },
              },
              '@keyframes float5': {
                '0%, 100%': { transform: 'translate(0, 0)' },
                '50%': { transform: 'translate(70px, -50px)' },
              },
            }}
          />
        ))}
      </Box>
      
      <Container maxWidth="xl">
        <Grid 
          container 
          spacing={3}
          alignItems="center"
          justifyContent="space-between"
          sx={{ minHeight: '80vh', position: 'relative', zIndex: 1 }}
        >
          {/* Text Content */}
          <Grid item xs={12} md={7} lg={6}>
            <Box
              sx={{
                textAlign: { xs: 'center', md: 'left' },
                py: { xs: 8, md: 0 },
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  mb: 2,
                  px: 2,
                  py: 0.5,
                  display: 'inline-block',
                  fontWeight: 500,
                  borderRadius: '20px',
                  backgroundImage: 'linear-gradient(45deg, rgba(142, 45, 226, 0.2), rgba(74, 0, 224, 0.2))',
                  backdropFilter: 'blur(4px)',
                  animation: 'fadeIn 1s ease-out',
                  '@keyframes fadeIn': {
                    '0%': { opacity: 0, transform: 'translateY(-20px)' },
                    '100%': { opacity: 1, transform: 'translateY(0)' },
                  },
                }}
              >
                DIGITAL TRANSFORMATION EXPERTS
              </Typography>
              
              <Typography
                variant="h1"
                component="h1"
                sx={{
                  fontWeight: 800,
                  mb: 2,
                  fontSize: { xs: '2.5rem', sm: '3rem', md: '3.5rem', lg: '4rem' },
                  animation: 'slideUp 0.9s ease-out',
                  '@keyframes slideUp': {
                    '0%': { opacity: 0, transform: 'translateY(30px)' },
                    '100%': { opacity: 1, transform: 'translateY(0)' },
                  },
                }}
              >
                We Help You <WordScrambler words={words} displayDuration={2000} transitionDuration={1000} />
              </Typography>
              
              <Typography
                variant="h6"
                component="p"
                sx={{
                  color: 'text.secondary',
                  maxWidth: '600px',
                  mx: { xs: 'auto', md: 0 },
                  mb: 4,
                  animation: 'slideUp 1.2s ease-out',
                }}
              >
                Custom software solutions tailored to bring your business into the future. 
                With expertise in low-code, process optimization, and dedicated support, 
                we elevate your digital experience.
              </Typography>
              
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: { xs: 'column', sm: 'row' },
                  gap: 2,
                  justifyContent: { xs: 'center', md: 'flex-start' },
                  animation: 'slideUp 1.4s ease-out',
                }}
              >
                <Button
                  variant="contained"
                  size="large"
                  sx={{
                    py: 1.5,
                    px: 4,
                    borderRadius: '50px',
                    fontSize: '1.1rem',
                    boxShadow: '0 10px 20px rgba(142, 45, 226, 0.3)',
                    '&:hover': {
                      boxShadow: '0 6px 15px rgba(142, 45, 226, 0.5)',
                    },
                  }}
                  onClick={() => document.getElementById('contact').scrollIntoView({ behavior: 'smooth' })}
                >
                  Get Started
                </Button>
                
                <Button
                  variant="outlined"
                  size="large"
                  sx={{
                    py: 1.5,
                    px: 4,
                    borderRadius: '50px',
                    fontSize: '1.1rem',
                    borderWidth: 2,
                    '&:hover': {
                      borderWidth: 2,
                    },
                  }}
                  onClick={() => document.getElementById('features').scrollIntoView({ behavior: 'smooth' })}
                >
                  Our Services
                </Button>
              </Box>
            </Box>
          </Grid>
          
          {/* Hero Image/Illustration - Only show on larger screens */}
          {!isMobile && (
            <Grid item xs={12} md={5} lg={6} sx={{ display: { xs: 'none', md: 'block' } }}>
              <Box
                sx={{
                  position: 'relative',
                  height: { md: '500px', lg: '600px' },
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  animation: 'fadeIn 1.5s ease-out',
                }}
              >
                {/* Abstract 3D shape made with CSS */}
                <Box
                  sx={{
                    position: 'relative',
                    width: { md: '380px', lg: '450px' },
                    height: { md: '380px', lg: '450px' },
                    borderRadius: '50%',
                    background: 'radial-gradient(circle, rgba(142, 45, 226, 0.2) 0%, rgba(74, 0, 224, 0.1) 70%)',
                    boxShadow: '0 0 60px rgba(142, 45, 226, 0.4)',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    animation: 'pulse 6s infinite ease-in-out',
                    '@keyframes pulse': {
                      '0%, 100%': {
                        transform: 'scale(1)',
                      },
                      '50%': {
                        transform: 'scale(1.05)',
                      },
                    },
                  }}
                >
                  {/* Inner circle */}
                  <Box
                    sx={{
                      width: '70%',
                      height: '70%',
                      borderRadius: '50%',
                      background: 'linear-gradient(135deg, rgba(142, 45, 226, 0.6), rgba(74, 0, 224, 0.4))',
                      boxShadow: '0 0 30px rgba(142, 45, 226, 0.6)',
                      position: 'relative',
                      '&::before': {
                        content: '""',
                        position: 'absolute',
                        top: '-10%',
                        left: '-10%',
                        width: '120%',
                        height: '120%',
                        borderRadius: '50%',
                        border: '2px solid rgba(142, 45, 226, 0.3)',
                        animation: 'spin 12s linear infinite',
                      },
                      '&::after': {
                        content: '""',
                        position: 'absolute',
                        top: '-5%',
                        left: '-5%',
                        width: '110%',
                        height: '110%',
                        borderRadius: '50%',
                        border: '4px solid rgba(74, 0, 224, 0.2)',
                        animation: 'spin 8s linear infinite reverse',
                      },
                      '@keyframes spin': {
                        '0%': {
                          transform: 'rotate(0deg)',
                        },
                        '100%': {
                          transform: 'rotate(360deg)',
                        },
                      },
                    }}
                  >
                    {/* Innermost circle */}
                    <Box
                      sx={{
                        position: 'absolute',
                        top: '15%',
                        left: '15%',
                        width: '70%',
                        height: '70%',
                        borderRadius: '50%',
                        background: 'linear-gradient(45deg, #8e2de2, #4a00e0)',
                        boxShadow: '0 0 40px rgba(142, 45, 226, 0.8)',
                      }}
                    />
                  </Box>
                </Box>
                
                {/* Floating elements */}
                {[...Array(5)].map((_, index) => (
                  <Box
                    key={index}
                    sx={{
                      position: 'absolute',
                      width: { md: '60px', lg: '80px' },
                      height: { md: '60px', lg: '80px' },
                      borderRadius: index % 2 ? '50%' : '20%',
                      background: 'linear-gradient(45deg, rgba(142, 45, 226, 0.8), rgba(74, 0, 224, 0.6))',
                      boxShadow: '0 4px 20px rgba(142, 45, 226, 0.5)',
                      top: `${20 + (index * 15)}%`,
                      left: `${10 + ((index % 3) * 30)}%`,
                      animation: `float${index + 1} ${4 + index}s infinite ease-in-out`,
                      '@keyframes float1': {
                        '0%, 100%': { transform: 'translateY(-10px) rotate(0deg)' },
                        '50%': { transform: 'translateY(10px) rotate(10deg)' },
                      },
                      '@keyframes float2': {
                        '0%, 100%': { transform: 'translateY(10px) rotate(0deg)' },
                        '50%': { transform: 'translateY(-10px) rotate(-10deg)' },
                      },
                      '@keyframes float3': {
                        '0%, 100%': { transform: 'translateX(-10px) rotate(5deg)' },
                        '50%': { transform: 'translateX(10px) rotate(-5deg)' },
                      },
                      '@keyframes float4': {
                        '0%, 100%': { transform: 'translateX(10px) translateY(5px)' },
                        '50%': { transform: 'translateX(-10px) translateY(-5px)' },
                      },
                      '@keyframes float5': {
                        '0%, 100%': { transform: 'scale(1) rotate(0deg)' },
                        '50%': { transform: 'scale(1.1) rotate(10deg)' },
                      },
                    }}
                  />
                ))}
              </Box>
            </Grid>
          )}
        </Grid>
      </Container>
      
      {/* Scroll Down Indicator */}
      <Box
        sx={{
          position: 'absolute',
          bottom: 40,
          left: '50%',
          transform: 'translateX(-50%)',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          opacity: 0.8,
          cursor: 'pointer',
          animation: 'bounce 2s infinite',
          '@keyframes bounce': {
            '0%, 20%, 50%, 80%, 100%': { transform: 'translateY(0) translateX(-50%)' },
            '40%': { transform: 'translateY(-20px) translateX(-50%)' },
            '60%': { transform: 'translateY(-10px) translateX(-50%)' },
          },
          zIndex: 1,
        }}
        onClick={handleScrollDown}
      >
        <Typography variant="body2" sx={{ mb: 1, fontSize: '0.9rem' }}>
          Scroll Down
        </Typography>
        <KeyboardArrowDownIcon />
      </Box>
    </Box>
  );
};

export default Hero;
// Footer.js
import React from 'react';
import {
  Box,
  Container,
  Grid,
  Typography,
  Link,
  IconButton,
  Divider,
  Button,
  useTheme,
  useMediaQuery,
} from '@mui/material';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import GitHubIcon from '@mui/icons-material/GitHub';
import LogoIcon from '@mui/icons-material/Code'; // Placeholder for your logo

const Footer = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  const navigate = useNavigate();
  
  // Scroll to top function
  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  // Helper function to handle navigation to sections
  const handleNavigation = (path) => {
    if (path.includes('#')) {
      const [pagePath, sectionId] = path.split('#');
      
      // Navigate to the page first
      if (window.location.pathname !== pagePath) {
        navigate(pagePath);
        // Add delay to ensure page is loaded before scrolling
        setTimeout(() => {
          const section = document.getElementById(sectionId);
          if (section) {
            section.scrollIntoView({ behavior: 'smooth', block: 'start' });
          }
        }, 500);
      } else {
        // If already on the correct page, just scroll to the section
        const section = document.getElementById(sectionId);
        if (section) {
          section.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      }
    } else {
      // For regular page navigation
      navigate(path);
      // Scroll to top when navigating to a new page
      window.scrollTo({ top: 0, behavior: 'smooth' });
    }
  };

  return (
    <Box
      component="footer"
      sx={{
        background: 'linear-gradient(180deg, rgba(15, 32, 39, 0.98) 0%, rgba(15, 32, 39, 0.98) 100%)',
        pt: 8,
        pb: 4,
        position: 'relative',
      }}
    >
      <Container maxWidth="lg">
        <Grid container spacing={6}>
          {/* Company Info */}
          <Grid item xs={12} md={4}>
            <Box 
              sx={{ 
                display: 'flex', 
                flexDirection: 'column', 
                height: '100%'
              }}
            >
              {/* Logo and Company Name */}
              <Box 
                sx={{ 
                  display: 'flex', 
                  alignItems: 'center',
                  mb: 2,
                  cursor: 'pointer'
                }}
                onClick={() => {
                  navigate('/');
                  window.scrollTo({ top: 0, behavior: 'smooth' });
                }}
              >
                <LogoIcon 
                  sx={{ 
                    fontSize: 36, 
                    color: theme.palette.primary.main,
                    mr: 1
                  }} 
                />
                <Typography
                  variant="h5"
                  sx={{
                    fontFamily: 'monospace',
                    fontWeight: 700,
                    letterSpacing: '.2rem',
                  }}
                >
                  REIGNOVATION
                </Typography>
              </Box>
              
              {/* Company Description */}
              <Typography 
                variant="body2" 
                color="text.secondary"
                sx={{ mb: 3 }}
              >
                Creating cutting-edge technology solutions to help businesses transform 
                and thrive in the digital age. With expertise in custom software, web 
                development, and process improvement consulting.
              </Typography>
              
              {/* Social Media Links */}
            </Box>
          </Grid>
          
          {/* Quick Links */}
          <Grid item xs={12} sm={6} md={2}>
            <Typography 
              variant="h6" 
              gutterBottom
              sx={{ fontWeight: 600 }}
            >
              Quick Links
            </Typography>
            <Box 
              component="ul" 
              sx={{ 
                listStyle: 'none', 
                p: 0, 
                m: 0
              }}
            >
              {[
                { name: 'Home', path: '/' },
                { name: 'Features', path: '/features' },
                { name: 'Consulting', path: '/consulting' },
              ].map((item) => (
                <Box component="li" key={item.name} sx={{ mb: 1 }}>
                  <Box
                    component="span"
                    sx={{
                      color: 'text.secondary',
                      textDecoration: 'none',
                      transition: 'all 0.2s',
                      display: 'inline-block',
                      cursor: 'pointer',
                      '&:hover': {
                        color: 'primary.main',
                        transform: 'translateX(5px)',
                      },
                    }}
                    onClick={() => {
                      navigate(item.path);
                      window.scrollTo({ top: 0, behavior: 'smooth' });
                    }}
                  >
                    {item.name}
                  </Box>
                </Box>
              ))}
            </Box>
          </Grid>
          
          {/* Service Links */}
          <Grid item xs={12} sm={6} md={3}>
            <Typography 
              variant="h6" 
              gutterBottom
              sx={{ fontWeight: 600 }}
            >
              Our Services
            </Typography>
            <Box 
              component="ul" 
              sx={{ 
                listStyle: 'none', 
                p: 0, 
                m: 0
              }}
            >
              {[
                { name: 'Custom Software Development', path: '/features' },
                { name: 'Web Development', path: '/features' },
                { name: 'Process Improvement', path: '/consulting' },
                { name: 'Project Research', path: '/consulting' },
                { name: 'Integration Services', path: '/consulting' },
                { name: 'Technical Support', path: '/features' }
              ].map((item) => (
                <Box component="li" key={item.name} sx={{ mb: 1 }}>
                  <Box
                    component="span"
                    sx={{
                      color: 'text.secondary',
                      textDecoration: 'none',
                      transition: 'all 0.2s',
                      display: 'inline-block',
                      cursor: 'pointer',
                      '&:hover': {
                        color: 'primary.main',
                        transform: 'translateX(5px)',
                      },
                    }}
                    onClick={() => {
                      navigate(item.path);
                      window.scrollTo({ top: 0, behavior: 'smooth' });
                    }}
                  >
                    {item.name}
                  </Box>
                </Box>
              ))}
            </Box>
          </Grid>
          
          {/* Contact Info */}
          <Grid item xs={12} md={3}>
            <Typography 
              variant="h6" 
              gutterBottom
              sx={{ fontWeight: 600 }}
            >
              Contact Us
            </Typography>
            <Box sx={{ mb: 2 }}>
              <Typography variant="body2" color="text.secondary" sx={{ mb: 1 }}>
                Email:
              </Typography>
              <Typography variant="body2" sx={{ fontWeight: 500 }}>
                support@reignovation.com
              </Typography>
            </Box>
            <Button
              variant="outlined"
              sx={{
                borderColor: theme.palette.primary.main,
                color: theme.palette.primary.main,
                borderRadius: '50px',
                px: 3,
                '&:hover': {
                  backgroundColor: 'rgba(142, 45, 226, 0.08)',
                  borderColor: theme.palette.primary.light,
                },
              }}
              onClick={() => handleNavigation('/#contact')}
            >
              Contact Us
            </Button>
          </Grid>
        </Grid>
        
        <Divider sx={{ my: 4, borderColor: 'rgba(255, 255, 255, 0.1)' }} />
        
        {/* Copyright */}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            flexDirection: { xs: 'column', sm: 'row' },
          }}
        >
          <Typography variant="body2" color="text.secondary">
            © {new Date().getFullYear()} Reignovation. All rights reserved.
          </Typography>
          <Box sx={{ mt: { xs: 2, sm: 0 } }}>
            <Link 
              component="span"
              sx={{ 
                color: 'text.secondary', 
                mx: 1,
                textDecoration: 'none',
                cursor: 'pointer',
                '&:hover': {
                  color: 'primary.main',
                },
              }}
              onClick={() => {
                navigate('/');
                window.scrollTo({ top: 0, behavior: 'smooth' });
              }}
            >
              Privacy Policy
            </Link>
            <Link 
              component="span"
              sx={{ 
                color: 'text.secondary', 
                mx: 1,
                textDecoration: 'none',
                cursor: 'pointer',
                '&:hover': {
                  color: 'primary.main',
                },
              }}
              onClick={() => {
                navigate('/');
                window.scrollTo({ top: 0, behavior: 'smooth' });
              }}
            >
              Terms of Service
            </Link>
          </Box>
        </Box>
      </Container>
      
      {/* Scroll to top button */}
      <IconButton
        aria-label="scroll to top"
        onClick={scrollToTop}
        sx={{
          position: 'absolute',
          right: 30,
          top: -25,
          background: theme.palette.primary.main,
          color: 'white',
          width: 50,
          height: 50,
          boxShadow: '0 4px 20px rgba(142, 45, 226, 0.4)',
          '&:hover': {
            background: theme.palette.primary.dark,
          },
        }}
      >
        <ArrowUpwardIcon />
      </IconButton>
    </Box>
  );
};

export default Footer;
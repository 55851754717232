// ProcessSection.js
import React, { useEffect, useRef } from 'react';
import { 
  Box, 
  Typography, 
  Container, 
  Grid, 
  Paper,
  useMediaQuery,
  useTheme
} from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import DesignServicesIcon from '@mui/icons-material/DesignServices';
import CodeIcon from '@mui/icons-material/Code';
import RocketLaunchIcon from '@mui/icons-material/RocketLaunch';

// Intersection Observer hook for animations
const useIntersectionObserver = (options) => {
  const ref = useRef(null);
  const [isVisible, setIsVisible] = React.useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setIsVisible(entry.isIntersecting);
    }, options);

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, [ref, options]);

  return [ref, isVisible];
};

// Process step component
const ProcessStep = ({ step, index, totalSteps, isVisible }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  
  // For animation timing
  const delay = `${0.2 + (index * 0.15)}s`;
  
  return (
    <Grid item xs={12} md={6} lg={3} sx={{ position: 'relative' }}>
      {/* Connection line between steps (not on mobile) */}
      {!isMobile && index < totalSteps - 1 && (
        <Box
          sx={{
            position: 'absolute',
            top: '100px',
            right: '-10%',
            width: '20%',
            height: '2px',
            background: 'linear-gradient(90deg, rgba(142, 45, 226, 0.7), rgba(74, 0, 224, 0.4))',
            zIndex: 0,
            opacity: isVisible ? 1 : 0,
            transition: 'opacity 0.6s ease',
            transitionDelay: delay,
          }}
        />
      )}
      
      <Paper
        elevation={0}
        sx={{
          p: 3,
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          textAlign: 'center',
          borderRadius: 4,
          background: 'rgba(26, 35, 50, 0.6)',
          backdropFilter: 'blur(10px)',
          border: '1px solid rgba(255, 255, 255, 0.1)',
          transition: 'transform 0.4s ease, box-shadow 0.4s ease',
          position: 'relative',
          zIndex: 1,
          opacity: isVisible ? 1 : 0,
          transform: isVisible ? 'translateY(0)' : 'translateY(40px)',
          transitionProperty: 'opacity, transform',
          transitionDuration: '0.6s',
          transitionTimingFunction: 'cubic-bezier(0.215, 0.61, 0.355, 1)',
          transitionDelay: delay,
          '&:hover': {
            transform: 'translateY(-10px)',
            boxShadow: '0 15px 30px rgba(0, 0, 0, 0.15)',
            '& .step-number': {
              transform: 'scale(1.1)',
              boxShadow: '0 8px 20px rgba(142, 45, 226, 0.4)',
            },
            '& .step-icon': {
              transform: 'scale(1.1) rotate(10deg)',
              color: theme.palette.primary.light,
            },
          },
        }}
      >
        {/* Step number */}
        <Box
          className="step-number"
          sx={{
            width: 40,
            height: 40,
            borderRadius: '50%',
            background: 'linear-gradient(135deg, #8e2de2, #4a00e0)',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            color: '#fff',
            fontWeight: 700,
            mb: 2,
            boxShadow: '0 6px 15px rgba(142, 45, 226, 0.3)',
            transition: 'all 0.3s ease',
          }}
        >
          {index + 1}
        </Box>
        
        {/* Step icon */}
        <Box
          className="step-icon"
          sx={{
            color: theme.palette.primary.main,
            fontSize: 50,
            mb: 2,
            transition: 'all 0.3s ease',
          }}
        >
          {step.icon}
        </Box>
        
        {/* Step title */}
        <Typography
          variant="h6"
          component="h3"
          sx={{
            fontWeight: 600,
            mb: 2,
          }}
        >
          {step.title}
        </Typography>
        
        {/* Step description */}
        <Typography
          variant="body2"
          sx={{
            color: 'text.secondary',
            lineHeight: 1.6,
          }}
        >
          {step.description}
        </Typography>
      </Paper>
    </Grid>
  );
};

// Main ProcessSection component
const ProcessSection = () => {
  const theme = useTheme();
  const [sectionRef, isSectionVisible] = useIntersectionObserver({
    threshold: 0.1,
  });
  
  // Process steps data
  const processSteps = [
    {
      icon: <SettingsIcon style={{ fontSize: 40 }} />,
      title: 'Discovery & Strategy',
      description: 'We begin by understanding your unique business needs and challenges. Our team collaborates with you to define a tailored strategy that aligns with your vision and objectives.',
    },
    {
      icon: <DesignServicesIcon style={{ fontSize: 40 }} />,
      title: 'Design & Prototyping',
      description: 'Our designers create user-focused prototypes that bring your ideas to life. We prioritize intuitive UX and engaging interfaces that align perfectly with your brand identity.',
    },
    {
      icon: <CodeIcon style={{ fontSize: 40 }} />,
      title: 'Development & Integration',
      description: 'Using modern, scalable technology, our developers build your solution with a focus on performance, security, and seamless integration with your existing systems.',
    },
    {
      icon: <RocketLaunchIcon style={{ fontSize: 40 }} />,
      title: 'Launch & Support',
      description: 'After rigorous testing, your solution is deployed with full operational support. We provide continuous monitoring, updates, and maintenance to ensure long-term success.',
    },
  ];
  
  return (
    <Box
      id="process"
      sx={{
        py: { xs: 10, md: 12 },
        background: 'linear-gradient(180deg, rgba(32, 58, 67, 0.95) 0%, rgba(15, 32, 39, 0.95) 100%)',
        position: 'relative',
      }}
      ref={sectionRef}
    >
      <Container maxWidth="xl">
        {/* Section header */}
        <Box 
          sx={{ 
            textAlign: 'center', 
            mb: { xs: 6, md: 8 },
            opacity: isSectionVisible ? 1 : 0,
            transform: isSectionVisible ? 'translateY(0)' : 'translateY(30px)',
            transition: 'opacity 0.6s ease, transform 0.6s ease',
          }}
        >
          <Typography
            variant="overline"
            component="p"
            sx={{
              color: 'primary.main',
              fontWeight: 600,
              letterSpacing: 1.5,
              mb: 1,
              display: 'inline-block',
            }}
          >
            HOW WE WORK
          </Typography>
          
          <Typography
            variant="h2"
            component="h2"
            sx={{
              fontWeight: 700,
              mb: 2,
              background: 'linear-gradient(90deg, #fff, #bcc5d0)',
              backgroundClip: 'text',
              WebkitBackgroundClip: 'text',
              WebkitTextFillColor: 'transparent',
              textFillColor: 'transparent',
            }}
          >
            Our Process
          </Typography>
          
          <Typography
            variant="subtitle1"
            sx={{
              color: 'text.secondary',
              maxWidth: '800px',
              mx: 'auto',
            }}
          >
            A structured, transparent approach that ensures your project succeeds at every stage.
            From initial concept to final delivery, we keep you informed and involved.
          </Typography>
        </Box>
        
        {/* Process steps */}
        <Grid container spacing={4} justifyContent="center">
          {processSteps.map((step, index) => (
            <ProcessStep 
              key={step.title} 
              step={step} 
              index={index} 
              totalSteps={processSteps.length}
              isVisible={isSectionVisible}
            />
          ))}
        </Grid>
      </Container>
    </Box>
  );
};

export default ProcessSection;
// LandingPage.js
import React from 'react';
import { Box } from '@mui/material';

// Components
import NavBar from '../components/NavBar';
import Hero from '../components/Hero';
import Highlights from '../components/Highlights';
import Features from '../components/Features';
import ProcessSection from '../components/ProcessSection';
import FAQ from '../components/FAQ';
import Contact from '../components/Contact';
import Footer from '../components/Footer';


const LandingPage = () => {
  return (
    <Box
      sx={{
        position: 'relative',
        overflowX: 'hidden',
        minHeight: '100vh',
        bgcolor: 'background.default',
        color: 'text.primary',
      }}
    >
      <NavBar />
      <Hero />
      <Highlights />
      <Features />
      <ProcessSection />
      <FAQ />
      <Contact />
      <Footer />
    </Box>
  );
};

export default LandingPage;